import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import apiHelper from "./API/ApiHelper";

const firebaseConfig = {
  apiKey: "AIzaSyC4MvWDEfNSTnH4FtmmYph_l6hhZMygHqA",
  authDomain: "aldera-3a67e.firebaseapp.com",
  projectId: "aldera-3a67e",
  storageBucket: "aldera-3a67e.appspot.com",
  messagingSenderId: "389161791126",
  appId: "1:389161791126:web:c011825afebb4ac4e49cb7",
};

const app = initializeApp(firebaseConfig);

export const messaging = getMessaging(app);

export const auth = getAuth(app);

export const provider = new GoogleAuthProvider();

if ("serviceWorker" in navigator)
{
  window.addEventListener("load", async () => {
    try
    {
      await getOrRegisterServiceWorker();
      await getFirebaseToken("add"); 
    
    } catch (error)
    {
      console.log("Service Worker registration failed:", error);
    }
  });
}

export const getOrRegisterServiceWorker = async () => {
  try
  {
    const serviceWorkerRegistration =
      await navigator.serviceWorker.getRegistration(
        "/firebase-push-notification-scope"
      );
    if (serviceWorkerRegistration)
    {
      return serviceWorkerRegistration;
    } else
    {
      return await navigator.serviceWorker.register(
        "/firebase-messaging-sw.js",
        {
          scope: "/firebase-push-notification-scope",
        }
      );
    }
  } catch (error)
  {
    throw new Error("Service Worker registration failed: " + error.message);
  }
};

export const getFirebaseToken = async (type) => {
  try
  {
    const serviceWorkerRegistration = await getOrRegisterServiceWorker();
    const deviceId = localStorage.getItem("deviceID");
    const token =
      deviceId &&
      (await getToken(messaging, {
        vapidKey:
          "BGCRwOri1RA6nHT73Nvzb5AwUcijoqw2Gm6i2udjHBCy3DYCGRH_I7hOW2xL9mhzFk58F8Zwek99uxD-UpX0Qp4",
        serviceWorkerRegistration,
      }));

    if (deviceId)
    {
      const params = {
        token: token || "", 
        device_id: deviceId,
      };

    
      if (type === "add")
      {
        await apiHelper.SET_FCM_token(params);
      } else if (type === "remove")
      {
        params.token = "0"; 
        await apiHelper.SET_FCM_token(params);
      }
    }
  } catch (error)
  {
    console.error("Failed to get Firebase token: ", error);
  }
};

export const onForegroundMessage = () => {
  return new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
};
