export const Validation_EN_json = {
    email: "Required field email is empty.",
    count: "Enter valid number",
    invalidEmail: "Please enter valid email.",
    name: "Required field name is empty.",
    inValidName: "Invalid name.",
    password: "Required field password is empty.",
    passNotMatch: "password not match",
    descri: "Required field description is empty.",
    property: "Required field property is empty",
    provience: "Required field area is empty",
    provienceRegex: "please enter valid text.",
    city: "Required field city is empty",
    location: "Required field location is empty",
    image: "Required field image is empty",
    price: "Required field price is empty",
    bed: "Required field bedroom is empty",
    floor:"Required field Floor is empty",
    basement:"Please select atleast one",
    propertyType:"Please select atleast one",
    bath: "Required field bathroom is empty",
    parking: "Required field parking is empty",
    tv: "Required field tv is empty",
    firstName: "Required field first name is empty",
    invalidFN: "Invalid first name",
    lastName: "Required field last name is empty",
    invalidLN: "Invalid last name",
    number: "Required field number is empty",
    invalidNumber: "Invalid number",
    address: "Required field address is empty",
    companyName: "Required field company name is empty",
    invalidComName: "Invalid company name",
    website: "Required field company website is empty",
    electronicNumber: "Required field electronic number is empty",
    commerLC: "Required field commercial license is empty",
    agencyLC: "Required field agency license is empty",
    logo: "Required field logo is empty",
    // -------------
    agencyID: "Required field agency id is empty",
    civilID: "Required field civil id is empty",
    floorArea: "Required field floor area is empty",
    soliQuality: "Required field soil quality type is empty",
    ydHistory: "Required field crop yield history is empty",
    govtRegu: "Required field govt regulations is empty",
    proximity: "Required field proximity is empty",
    demand: "Required field demand is empty",
    potential: "Required field potential is empty",
    // resident
    propertySize: "Required field property size is empty",
    washroom: "Required field no of washroom is empty",
    propertyAge: "Required field age of property is empty",
    propertyValue: "Required field property value is empty",
    mANDq: "Required field materials and quality is empty",
    //industry
    area: "Required field area is empty",
    ceilingHeight: "Required field ceiling height is empty",
    loading: "Required field loading capacity is empty",
    RnP: "Required field regulations and permition is empty",
    lease: "Required field rent lease rates is empty",
    //commercial
    rentalIncome: "Required field rental income is empty",
    pSpace: "Required field parking space is empty",
    oRate: "Required field occupancy rates is empty",
    economy: "Required field economy factors is empty",
    fiveRequired: "Please select atleast 5 items",
    fileFormate: `Invalid {{type}} format. Please select a valid document (jpg, jpeg, png, gif).`
}
// export const Validation_AR_json = {
//     email: "البريد الالكتروني مطلوب!",//n
//     count: "أدخل رقمًا صالحًا",
//     invalidEmail: "البريد الالكتروني مطلوبl",//n
//     name: "اسم الحقل المطلوب فارغ",
//     inValidName: "اسم غير صالح",
//     password: "كلمة السر مطلوبة",//n
//     passNotMatch: "كلمة السر غير متطابقة!",//n
//     descri: "الوصف مطلوب",//n
//     property: "خاصية الحقل المطلوبة فارغة",
//     provience: "المحافظة مطلوبة",//n
//     provienceRegex: "الرجاء إدخال نص صالح.",
//     city: "المدينة مطلوبة",//n
//     location: "المنطقة مطلوبة",//n
//     image: "الصورة مطلوبة!",//n
//     price: "السعر مطلوب",//n
//     bed: "غرفة النوم الميدانية المطلوبة فارغة",
//     bath: "مطلوب الحمام الميداني فارغ",
//     parking: "مطلوب وقوف السيارات الميدانية فارغة",
//     tv: "حقل التلفزيون المطلوب فارغ",
//     firstName: "الاسم الأول مطلوب!",//n
//     invalidFN: "الاسم الأول غير صالح",
//     lastName: "الاسم الأخير مطلوب!",//n
//     invalidLN: "الاسم الأخير غير صالح",
//     number: "رقم الهاتف مطلوب",//n
//     invalidNumber: "رقم غير صالح",
//     address: "العنوان مطلوب",//n
//     companyName: "اسم الشركة مطلوب",//n
//     invalidComName: "اسم الشركة غير صالح",
//     website: "الحقل المطلوب موقع الشركة فارغ",
//     electronicNumber: "الرقم الالكتروني للرخصة مطلوب",//n
//     commerLC: "الرخصة التجارية مطلوبة",//n
//     agencyLC: "رخصة المكتب مطلوبة",//n
//     logo: "صورة شعار الشركة مطلوبة",//n

//     //----------------------
//     agencyID: "اسم مكتب العقار مطلوب!",//n
//     civilID: "الرقم المدني مطلوب",//n
//     floorArea: "مساحة أرضية الحقل المطلوبة فارغة",
//     soliQuality: "نوع نوعية التربة الحقلية المطلوبة فارغ",
//     ydHistory: "سجل إنتاجية محصول الحقل المطلوب فارغ",
//     govtRegu: "الحقل المطلوب اللوائح الحكومية فارغ",
//     proximity: "حقل القرب المطلوب فارغ",
//     demand: "حقل الطلب المطلوب فارغ",
//     potential: "إمكانات الحقل المطلوبة فارغة",

//     // resident
//     propertySize: "المساحة مطلوبة",//n
//     washroom: "الحقل المطلوب رقم الحمام فارغ",
//     propertyAge: "عمر العقار مطلوب",//n
//     propertyValue: "قيمة خاصية الحقل المطلوبة فارغة",
//     mANDq: "المواد الميدانية المطلوبة والجودة فارغة",
//     //industry
//     area: "مساحة الحقل المطلوبة فارغة",
//     ceilingHeight: "ارتفاع سقف الحقل المطلوب فارغ",
//     loading: "سعة تحميل الحقل المطلوبة فارغة",
//     RnP: "حقل التنظيم والترقية المطلوب فارغ",
//     lease: "معدلات إيجار الإيجار الميدانية المطلوبة فارغة",
//     //commercial
//     rentalIncome: "دخل إيجار الحقل المطلوب فارغ",
//     pSpace: "المساحة المطلوبة لوقوف السيارات في الميدان فارغة",
//     oRate: "معدلات إشغال الحقل المطلوبة فارغة",
//     economy: "عوامل الاقتصاد الميداني المطلوبة فارغة",
//     fiveRequired: "يرجى تحديد 5 عناصر على الأقل",
//     fileFormate: `تنسيق {{type}} غير صالح. الرجاء تحديد مستند صالح (jpg، jpeg، png، gif).`

// }

export const Validation_AR_json = {
    email: "البريد الإلكتروني مطلوب!",
    count: "أدخل رقمًا صالحًا",
    invalidEmail: "الرجاء إدخال بريد إلكتروني صالح.",
    name: "اسم الحقل المطلوب فارغ",
    inValidName: "اسم غير صالح",
    password: "كلمة المرور مطلوبة",
    passNotMatch: "كلمة المرور غير متطابقة",
    descri: "الوصف مطلوب",
    basement:"الرجاء اختيار واحد على الأقل",
    floor:"الحقل المطلوب الطابق فارغ",
    propertyType:"الرجاء اختيار واحد على الأقل",
    property: "خاصية الحقل المطلوبة فارغة",
    provience: "حقل المنطقة المطلوب فارغ",
    provienceRegex: "الرجاء إدخال نص صالح.",
    city: "المدينة مطلوبة",
    location: "الموقع مطلوب",
    image: "الصورة مطلوبة",
    price: "السعر مطلوب",
    bed: "غرفة النوم مطلوبة",
    bath: "الحمام مطلوب",
    parking: "موقف السيارات مطلوب",
    tv: "التلفزيون مطلوب",
    firstName: "الاسم الأول مطلوب",
    invalidFN: "الاسم الأول غير صالح",
    lastName: "الاسم الأخير مطلوب",
    invalidLN: "الاسم الأخير غير صالح",
    number: "رقم الهاتف مطلوب",
    invalidNumber: "رقم غير صالح",
    address: "العنوان مطلوب",
    companyName: "اسم الشركة مطلوب",
    invalidComName: "اسم الشركة غير صالح",
    website: "موقع الشركة مطلوب",
    electronicNumber: "الرقم الإلكتروني مطلوب",
    commerLC: "الرخصة التجارية مطلوبة",
    agencyLC: "رخصة المكتب مطلوبة",
    logo: "شعار الشركة مطلوب",

    //----------------------
    agencyID: "رقم وكالة العقار مطلوب",
    civilID: "الرقم المدني مطلوب",
    floorArea: "مساحة الأرضية مطلوبة",
    soliQuality: "نوعية التربة مطلوبة",
    ydHistory: "سجل إنتاجية المحصول مطلوب",
    govtRegu: "اللوائح الحكومية مطلوبة",
    proximity: "القرب مطلوب",
    demand: "الطلب مطلوب",
    potential: "الإمكانات مطلوبة",

    // resident
    propertySize: "حجم العقار مطلوب",
    washroom: "عدد الحمامات مطلوب",
    propertyAge: "عمر العقار مطلوب",
    propertyValue: "قيمة العقار مطلوبة",
    mANDq: "المواد والجودة مطلوبة",
    //industry
    area: "المساحة مطلوبة",
    ceilingHeight: "ارتفاع السقف مطلوب",
    loading: "سعة التحميل مطلوبة",
    RnP: "التنظيم والتصريح مطلوبان",
    lease: "معدلات الإيجار مطلوبة",
    //commercial
    rentalIncome: "دخل الإيجار مطلوب",
    pSpace: "مساحة وقوف السيارات مطلوبة",
    oRate: "معدلات الإشغال مطلوبة",
    economy: "العوامل الاقتصادية مطلوبة",
    fiveRequired: "يرجى تحديد 5 عناصر على الأقل",
    fileFormate: `تنسيق {{type}} غير صالح. الرجاء تحديد مستند صالح (jpg، jpeg، png، gif).`
}
