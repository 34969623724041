import React, { useEffect } from "react";
import CustomerLoginDialogue from "../../screen/SignIn/CustomerLoginDialogue";

export default function SignUpComponent({
  setuserInfo,
  setuserProfileInfo,
  setGoogleLoginData,
  SetIsLoginGuest,
  setUserLogoutAction,
  openLoginModal,
  setOpenLoginModal,
  handleOpen,
  themeMode,
  isLogInGuest,
  title,
  listStyleLink,
  userInfo,
}) {
  useEffect(() => {}, [isLogInGuest, userInfo]);
  return (
    <li
      style={{
        listStyle: "none",
        padding: "0 15px",
        display: isLogInGuest ? "block" : "none",
      }}
    >
      {
        <div style={listStyleLink}>
          <CustomerLoginDialogue
            displayName={title}
            themeMode={themeMode}
            handleOpen={handleOpen}
            setOpenLoginModal={setOpenLoginModal}
            openLoginModal={openLoginModal}
            setUserLogoutAction={setUserLogoutAction}
            SetIsLoginGuest={SetIsLoginGuest}
            setGoogleLoginData={setGoogleLoginData}
            setuserProfileInfo={setuserProfileInfo}
            setuserInfo={setuserInfo}
          />
        </div>
      }
    </li>
  );
}
