import React, { useEffect, useState } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import apiHelper from "../../common/API/ApiHelper";
import moment from "moment";
import NotificationCard from "./NotificationCard";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

function NotificationScreen() {
  const [data, setData] = useState([]);
  const theme = useTheme();
  const [loading, setloading] = useState(false);
  const { t } = useTranslation();

  const FetchPropertyImage = async (id) => {
    try {
      const res = await apiHelper.getPropertyDetailsByID(id);
      return { img: res.data?.r?.image[0] && res.data?.r.image[0]?.image ,};
    } catch (error) {
      console.log(error);
    }
  };

  const handleRequst = async (is_accept, user_req_id) => {
    try {
      const data = {
        is_accept: is_accept,
        user_req_id: user_req_id,
      };
      await apiHelper.PropertyChatRequest(data);

      setData((data) =>
        data?.map((x) => {
          if (x?.user_req_id === user_req_id) {
            return { ...x, is_accept: is_accept === 1 ? 1 : -1 };
          } else {
            return x;
          }
        })
      );
    } catch (error) {}
  };

  const getData = async () => {
    try {
      setloading(true);
      const res = await apiHelper.GetNotification();
      const newData = await Promise.all(
        res?.data?.r?.map(async (item) => {
          const img = await FetchPropertyImage(item?.type_id);
          const date = moment(item.created_at).from(new Date(Date.now()));
          return { ...item, date: date, image: img && img.img };
        })
      );
      setData(newData);
      setloading(false);
    } catch (error) {
      setloading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getData(); //eslint-disable-next-line
  }, []);
  return (
    <Box
      sx={{
        p: "7rem 1rem 1rem 1rem ",
        height: `calc(100vh)`,
        bgcolor: theme.palette.navbarBG.main,
        color: theme.palette.dark.main,
        boxSizing: "border-box",
        overflow: "scroll",
        // border:"solid red",
      }}
    >
      <Typography
        id="modal-modal-title"
        textAlign={"center"}
        variant="h6"
        component="h2"
      >
        {t("notf.title")}
      </Typography>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      ) : data.length === 0 && !loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: `calc(100vh - 165px)`,
            width: "100%",
            color: "grey",
          }}
        >
          {t("notf.NoData")}
        </Box>
      ) : (
        data &&
        data?.map((i) => {
          return (
            <NotificationCard
              key={i.id}
              data={i}
              loading={loading}
              handleRequst={handleRequst}
            />
          );
        })
      )}
    </Box>
  );
}

export default NotificationScreen;
