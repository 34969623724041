import { Box, Typography, useTheme } from "@mui/material";
import React, { useContext } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { LanguageContext } from "../../Context/LanguageContext";
import InputComponent from "../Input/InputComponentTextField";
import { useTranslation } from "react-i18next";

function LocationSearchField({
  searchType,
  p,
  textColor,
  address,
  placeholder,
  handleAddress,
  updateLatLng,
  h,
  placeholderColor,
  label = "Address",
  size = "small",
  disabled = false,
  width,
  className,
  color,
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const { language } = useContext(LanguageContext);

  const handleSelect = (address) => {
    handleAddress(address);
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => updateLatLng(latLng))
      .catch((error) => console.error("Error", error));
  };

  const typesToSearch = searchType
    ? [searchType]
    : ["geocode", "establishment"];

  return (
    <PlacesAutocomplete
      value={address && address}
      onChange={handleAddress}
      onSelect={handleSelect}
      searchOptions={{
        types: typesToSearch,
      }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <Box
          width={width}
          borderRadius="12px"
          display="flex"
          justifyContent="start"
          alignItems="center"
          py="12px"
          position={"relative"}
        >
          <Box
            className={className || ""}
            sx={{ border: "none", mt: -1, mb: -1 }}
            width="100%"
          >
            <InputComponent
              type="text"
              id="location"
              name="location"
              sx={{
                bgcolor: color,
                borderRadius: "12px",
                width: { width },
                paddingTop: `0 !important`,
                paddingBottom: `0 !important`,
                fontSize: "20px",
                fontWeight: "500",
                border: "transparent",
                " .MuiOutlinedInput-input": {
                  textAlign: language === "ar" ? "right" : "left",
                },
                input: {
                  // "&::placeholder": {
                  textAlign: language === "ar" ? "right" : "left",
                  color: textColor,
                  // opacity: "1",
                  // },
                  // color: textColor,
                },
                "& .MuiOutlinedInput-input": {
                  padding: p ? `${p} !important` : "14px",
                },

                "& fieldset": { border: "none" },
              }}
              {...getInputProps({
                disabled: disabled,
                placeholder: placeholder || "Enter Address",
                className: "location-search-input",
                // "&::placeholder": {
                //   color: placeholderColor || "#fff",
                // },
              })}
              InputLabelProps={{
                shrink: true,
              }}
              className="form-control"
              placeholder={t(
                "landingPage.homeSection.searchPropertyFilters.location"
              )}
            />

            <Box
              sx={{
                width: "100%",
                minWidth: "285px",
                display: suggestions.length ? "block" : "none",
                bgcolor: theme.palette.mode === "light" ? "#FFF" : "#2f2f2f",
                borderRadius: "12px",
                p: 1,
                boxSizing: "border-box",
                zIndex: 99999,
                mt: "0.5rem",
                position: "absolute",
                fontSize: "22px",
                fontFamily: "Roboto",
              }}
            >
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion?.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                return (
                  <Box
                    sx={{
                      color: textColor,
                      textAlign: "left",
                      cursor: "pointer",
                      borderBottom: "solid 0.01px",
                      borderBottomColor: "divider",
                      fontSize: "1rem",
                      p: "1rem 0",
                    }}
                    width={"100%"}
                    key={suggestion?.description}
                    {...getSuggestionItemProps(suggestion, {
                      className,
                    })}
                  >
                    <Typography
                      sx={{
                        color:
                          theme.palette.mode === "light"
                            ? `${suggestion?.active ? "#1f243870" : "#1f2438"}`
                            : `${suggestion?.active ? "#ffffff70" : "#fff"}`,
                      }}
                      variant="subtitle2"
                    >
                      {suggestion?.description}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
      )}
    </PlacesAutocomplete>
  );
}

export default LocationSearchField;
