import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { FormHelperText } from "@mui/material";
import { LanguageContext } from "../../Context/LanguageContext";
import { useTheme } from "@mui/system";

export default function InputComponent(props) {
  const theme = useTheme()
  const { language } = React.useContext(LanguageContext);
  const {
    type,
    onClick,
    inputMode,
    onChange,
    value,
    placeholder,
    isError,
    helperText,
    name,
    id,
    multiline,
    sx,
    rows,
    style,
    maxRows,
    readOnly,
    inputProps,
    disabled
    // inputRef,
    // onKeyPress,
  } = props;
  return (
    <Box sx={{ width: "100%" }}>
      <TextField
        sx={{
          // input: { color: `#1f2438` },
          "& fieldset": { border: "none" },
          ...(sx || {}),
          "& .MuiOutlinedInput-input": {
            padding: "14px ",
          },
          direction: language === "ar" ? "rtl" : "ltr",
          textAlign: language === "ar" ? "right" : "left",
        }}
        InputProps={{
          style: {
            ...style,
            // color: `#1f2438`,
          },
        }}
        placeholder={placeholder || ""}
        onChange={onChange || null}
        value={value || ""}
        type={type}
        name={name}
        inputMode={inputMode}
        variant="outlined"
        id={id || ""}
        required
        multiline={multiline || false}
        rows={rows || 0}
        maxRows={maxRows || 0}
        readOnly={readOnly || false}
        inputProps={{ ...inputProps }}
        onClick={onClick || null}
        autoComplete="off"
        disabled={disabled}
      />
      {isError ? (
        <FormHelperText
          sx={{
            direction: language === "ar" ? "rtl" : "ltr",
            textAlign: language === "ar" ? "right" : "left",
            color: theme.palette.mode === "light" ? "#BE3144" : "rgb(255 40 69)",
            fontSize: "0.8rem",
            pl: "0.5rem",
          }}
          id={id}
        >
          {helperText}
        </FormHelperText>
      ) : (
        ""
      )}
    </Box>
  );
}
