import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { NoData } from "../../../common/Svg";
import { useParams, useNavigate } from "react-router-dom";
import apiHelper from "../../../common/API/ApiHelper";
import { toast } from "react-toastify";
import { baseUrl } from "../../../common/Constant";
import { LanguageContext } from "../../../Context/LanguageContext";
import Loader from "../../../common/Loader";
import ScrollAnimation from "react-animate-on-scroll";
import Path from "../../../common/Path";
// import ImageListModal from "./ImageListModal";
import DetailsComponent from "./Datails/DetailsComponent";
import { useSwipeable } from "react-swipeable";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export default function UpdatedPropertyDetailScreen({ verifyUserLogin }) {
  const { id } = useParams();
  const [isLoggedIn, setisLoggedIn] = useState();
  const theme = useTheme();
  const [propertyDetails, setPropertyDetails] = useState();
  const [loading, setLoading] = useState(true);
  const { language } = useContext(LanguageContext);
  const [CurrentUserInfo, setCurrentUserInfo] = useState();
  const navigate = useNavigate();
  // const userInfo = JSON.parse(localStorage.getItem("userInfo")) ?? {};
  const [trans, setTrans] = useState();
  const [newPrice, setnewPrice] = useState();
  const [reqLoading, setreqLoading] = useState(false);
  const [modalData, setmodalData] = useState([]);
  const thumbnailRefs = React.useRef([]);
  const [activeImgIndex, setActiveImgIndex] = React.useState(0);
  let userInfo;
  try {
    userInfo = JSON.parse(localStorage.getItem("userInfo"));
  } catch (error) {
    userInfo = null;
  }

  const handleVideoLoad = () => {
    setLoading(false);
  };

  const handleNext = () => {
    setActiveImgIndex((prevIndex) => {
      const newIndex = (prevIndex + 1) % modalData?.length;
      thumbnailRefs.current[newIndex].scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
      return newIndex;
    });
  };

  const handlePrev = () => {
    setActiveImgIndex((prevIndex) => {
      const newIndex = (prevIndex - 1 + modalData?.length) % modalData?.length;
      thumbnailRefs.current[newIndex].scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
      return newIndex;
    });
  };

  const fetchDetails = async () => {
    try {
      setLoading(true);
      const result = await apiHelper.getPropertyDetailsByID(id);
      setPropertyDetails(result?.data?.r);
      setTimeout(() => {
        setLoading(false);
      }, 100);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const agencyList =
    propertyDetails && propertyDetails?.agency?.map((x) => x?.company_name);

  async function handlePropertyrequest() {
    if (!isLoggedIn) {
      return verifyUserLogin();
    } else {
      try {
        setreqLoading(true);
        const res = await apiHelper.SendPropertyRequest(id);
        res?.data?.s === 1 &&
          res?.data?.m === "Success" &&
          toast.success(
            language === "en"
              ? "Your request has been sent successfully!"
              : "تم ارسال طلبك بنجاح!"
          );
        setPropertyDetails((prevPropertyDetails) => ({
          ...prevPropertyDetails,
          is_i_requested: {
            ...prevPropertyDetails.is_i_requested,
            status: 1,
          },
        }));
        setreqLoading(true);
      } catch (error) {
        setreqLoading(false);
        console.log(error);
      }
    }
  }

  const handlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrev,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const handleTransportation = () => {
    const transportationOptions = {
      1: "Highway",
      2: "Bus",
      3: "Airport",
      4: "Port",
    };

    const data = propertyDetails?.near_by_transport?.split(",") || [];
    const newTrans = data?.map(
      (option) =>
        transportationOptions[option] || transportationOptions?.default
    );
    setTrans(newTrans);
  };

  useEffect(() => {
    if (propertyDetails) {
      handleTransportation();
    } //eslint-disable-next-line
  }, [propertyDetails]);

  useEffect(() => {
    setCurrentUserInfo(userInfo);

    if (
      !userInfo ||
      (userInfo.id === 0 &&
        userInfo.role === 4 &&
        userInfo.email === "guestuser@gmail.com")
    ) {
      setisLoggedIn(false);
    } else {
      setisLoggedIn(true);
    } //eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    fetchDetails(); //eslint-disable-next-line
    //eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    setmodalData([
      ...(propertyDetails?.image ?? []),
      ...(propertyDetails?.video ?? []),
    ]);
  }, [propertyDetails]);

  const HandleChatNav = async (prop) => {
    const { data } = await apiHelper.GetChatUserId(prop.user_id);
    navigate(
      propertyDetails.user.role === 2
        ? `${Path.CHATBYID}?chatId=${data.r}&img=${
            prop?.user?.agency_logo
          }&name=${`${prop?.user?.company_name}`}&uId=${prop.user_id}&role=${
            prop.role
          }`
        : `${Path.CHATBYID}?chatId=${data.r}&img=${
            prop?.user?.profile
          }&name=${`${prop?.user?.f_name} ${prop?.user?.l_name}`}&uId=${
            prop.user_id
          }&role=${prop.role}`
    );
  };

  const handlePropertyStatus = async () => {
    try {
      const newStatus = propertyDetails?.status === 1 ? 2 : 1;
      const res = await apiHelper.updatePropertyStatus(
        propertyDetails?.id,
        newStatus
      );
      if (res?.data?.s === 1) {
        setPropertyDetails({ ...propertyDetails, status: newStatus });
        const message =
          newStatus === 2
            ? "Property Marked as Sold"
            : "Property Marked as Unsold";
        toast.success(message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function formatUSD(value) {
    if (value >= 1000000000) {
      return (value / 1000000000)?.toFixed(1) + "B";
    } else if (value >= 1000000) {
      return (value / 1000000).toFixed(1) + "M";
    } else if (value >= 1000) {
      return (value / 1000).toFixed(1) + "K";
    } else {
      return value.toFixed(1);
    }
  }

  useEffect(() => {
    if (propertyDetails) {
      const formattedPrice = formatUSD(propertyDetails?.price);
      setnewPrice(formattedPrice); //eslint-disable-next-line
    }
  }, [propertyDetails]);

  return (
    <Container
      maxWidth={"xxl"}
      sx={{
        position: "relative",
        top: "76px",
        minHeight: { md: `calc(100vh - 76px)` },
        padding: "1.15rem 1rem",
        bgcolor: theme.palette.navbarBG.main,
        color: theme.palette.dark.main,
        overflow: "scroll",
      }}
    >
      {loading ? (
        <Box
          sx={{
            height: `calc( 100vh - 113px)`,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader />
        </Box>
      ) : propertyDetails === undefined ? (
        <Box
          sx={{
            height: `calc( 100vh - 115px)`,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            bgcolor: theme.palette.secondary.main,
            color: theme.palette.dark.main,
          }}
        >
          <NoData />
        </Box>
      ) : (
        <>
          {/* <ScrollAnimation animateIn="animate__fadeIn"> */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: { md: `calc( 100vh - 130px)` },
            }}
          >
            <Grid
              container
              // spacing={3}
              gap={2}
              style={{
                // height: { md: "calc(100vh - 300px)" },
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={5.8}
                lg={5}
                sx={{
                  background: {
                    md:
                      theme.palette.mode === "light"
                        ? "#FFFFFF77"
                        : `#FFFFFF17`,
                  },
                  padding: { md: "15px" },
                  boxSizing: "border-box",
                  borderRadius: "12px",
                  alignItems: "center",
                  display: "flex",
                  position: "relative",

                  justifyContent: "center",
                }}
                {...handlers}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: { xs: "250px", sm: "350px", md: "100%", lg: "600" },
                  }}
                >
                  <IconButton
                    onClick={handlePrev}
                    sx={{
                      position: "absolute",
                      left: { xs: "0px", md: "15px" },
                      top: "50%",
                      transform: "translateY(-50%)",
                      zIndex: 1,
                      color: "#D4AB77",
                      "&:hover": {
                        color: "white",
                      },
                    }}
                  >
                    <ArrowBackIosIcon />
                  </IconButton>
                  {modalData[0]?.image && modalData[activeImgIndex]?.image && (
                    <img
                      src={`${baseUrl}/uploads/property/image/${modalData[activeImgIndex]?.image}`}
                      height={"100%"}
                      width={"100%"}
                      alt={`Slider-${modalData[activeImgIndex]?.id}`}
                      style={{
                        borderRadius: "15px",
                        maxHeight: "calc(100vh - 290px)",
                      }}
                    />
                  )}
                  {modalData && modalData[activeImgIndex]?.video && (
                    <div
                      style={{
                        position: "relative",
                        height: "100%",
                        width: "100%",
                        zIndex: 1,
                      }}
                    >
                      {loading && (
                        <CircularProgress
                          size={40}
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                        />
                      )}
                      <video
                        key={modalData[activeImgIndex]?.video}
                        autoPlay
                        height={"100%"}
                        width={"100%"}
                        muted
                        loop
                        onCanPlayThrough={handleVideoLoad}
                        style={{
                          display: loading ? "none" : "block",
                          borderRadius: "15px",
                          objectFit: "cover",
                          maxHeight: "calc(100vh - 290px)",
                        }}
                      >
                        <source
                          src={`${baseUrl}/uploads/property/video/${modalData[activeImgIndex]?.video}`}
                        />
                      </video>
                    </div>
                  )}

                  <IconButton
                    onClick={handleNext}
                    sx={{
                      position: "absolute",
                      right: { xs: "0px", md: "15px" },
                      top: "50%",
                      transform: "translateY(-50%)",
                      zIndex: 1,
                      color: "#D4AB77",
                      "&:hover": {
                        // transform: "Scale(1.1)",
                        color: "white",
                      },
                    }}
                  >
                    <ArrowForwardIosIcon />
                  </IconButton>
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    bottom: "10px",
                    display: { xs: "none", md: "flex" },
                    boxSizing: "border-box",
                    // padding: { xs: "0", md: "1.5rem" },
                    alignItems: "center",
                    justifyContent: { md: "start", lg: "center" },
                    overflowX: "auto",
                    overflowY: "hidden",
                    gap: "1rem",
                    width: { md: "calc(100% - 30px)", xl: "fitContent" },
                    padding: "10px",
                    background: theme.palette.secondary.main,
                    borderRadius: "25px",
                    // border: "solid red"
                  }}
                >
                  {modalData.length &&
                    modalData?.map((x, i) => (
                      <Box
                        {...handlers}
                        key={i}
                        ref={(el) => (thumbnailRefs.current[i] = el)}
                        sx={{
                          height: { md: "60px", lg: "80px" },
                          width: { md: "100px", lg: "120px" },
                          flex: "0 0 auto",
                          cursor: "pointer",
                          border:
                            activeImgIndex === i ? `2px solid #D4AB77` : "none",
                          borderRadius: "15px",
                        }}
                        onClick={() => {
                          setActiveImgIndex(i);
                          thumbnailRefs.current[i].scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                            inline: "center",
                          });
                        }}
                      >
                        {x.image && (
                          <img
                            src={`${baseUrl}/uploads/property/image/${x.image}`}
                            height={"100%"}
                            width={"100%"}
                            alt={`Slider-${x.id}`}
                            style={{
                              borderRadius: "15px",
                              objectFit: "cover",
                            }}
                          />
                        )}
                        {x.video && (
                          <video
                            key={x.video}
                            height={"100%"}
                            width={"100%"}
                            muted
                            controls
                            style={{
                              objectFit: "cover",
                              borderRadius: "15px",
                            }}
                          >
                            <source
                              src={`${baseUrl}/uploads/property/video/${x?.video}`}
                            />
                          </video>
                        )}
                      </Box>
                    ))}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={5.8} lg={6.5}>
                <Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: { xs: "20px", md: "30px", xl: "40px" },
                      }}
                    >
                      {propertyDetails?.type_name}
                    </Typography>
                    <Box sx={{ display: "flex", gap: "20px" }}>
                      <Button
                        sx={{
                          background: theme.palette.gold.main,
                          color: "#FFF",
                          mt: "0.05rem",
                          borderRadius: "30px",
                          textTransform: "none",
                          cursor: "default",
                          pointerEvents: "none",
                          padding: "5px 25px",
                          fontSize: { xs: "16px", md: "18px", xl: "22px" },
                        }}
                        variant="contained"
                      >
                        KWD {newPrice}
                      </Button>
                      <Button
                        sx={{
                          background: theme.palette.gold.main,
                          color: "#FFF",
                          mt: "0.05rem",
                          borderRadius: "30px",
                          textTransform: "none",
                          padding: "5px 25px",
                          fontSize: { xs: "16px", md: "18px", xl: "22px" },
                          cursor:
                            CurrentUserInfo?.role !== 4 ||
                            CurrentUserInfo?.role !== 5
                              ? "default"
                              : "pointer",
                          pointerEvents:
                            CurrentUserInfo?.role !== 4 ||
                            CurrentUserInfo?.role !== 5
                              ? "none"
                              : "auto",
                          display:
                            CurrentUserInfo?.role !== 3 ||
                            CurrentUserInfo?.role !== 5
                              ? "none"
                              : "block",
                        }}
                        variant="contained"
                        onClick={handlePropertyStatus}
                      >
                        {propertyDetails?.status === 1
                          ? "Mark as Sold"
                          : "Mark as Unsold"}
                      </Button>
                    </Box>
                  </Box>
                  <Box>
                    <Typography
                      style={{
                        fontWeight: "400",
                        color: `${theme.palette.dark.light}`,
                      }}
                      sx={{ fontSize: { xs: "16px", md: "20px", xl: "25px" } }}
                    >
                      For {propertyDetails?.property_for === 1 ? "Buy" : "Rent"}
                    </Typography>
                  </Box>
                </Box>
                <hr />
                <DetailsComponent
                  propertyDetails={propertyDetails}
                  CurrentUserInfo={CurrentUserInfo}
                  setPropertyDetails={setPropertyDetails}
                />
              </Grid>
            </Grid>
          </Box>

          {/* 

              
           

                <Box
                  sx={{
                    display:
                      CurrentUserInfo?.email === propertyDetails?.user?.email
                        ? "none"
                        : "flex",
                    justifyContent: {
                      xs: "center",
                      lg: "end",
                    },
                    paddingBottom: "1rem",
                    width: { xs: "100%", lg: "40%" },
                  }}
                >
                  <Box sx={{ p: { xs: "1rem 0" } }}>
                    <Button
                      variant="contained"
                      sx={{
                        borderRadius: "12px",
                        mr: "1rem",
                        padding: "12px 15px",
                      }}
                      disabled={
                        propertyDetails?.is_i_requested?.request_status === 1
                          ? false
                          : true
                      }
                      onClick={() => HandleChatNav(propertyDetails)}
                    >
                      <ChatTextSVG color={theme.palette.light.main} />
                    </Button>
                    <Button
                      sx={{
                        background: theme.palette.gold.main,
                        color: "#FFF",
                        borderRadius: "12px",
                        textTransform: "capitalize",
                      }}
                      variant="contained"
                      disabled={
                        reqLoading ||
                        CurrentUserInfo?.email ===
                          propertyDetails?.user?.email ||
                        propertyDetails?.is_i_requested?.status === 1 ||
                        propertyDetails?.is_i_requested?.status === 0
                      }
                      onClick={() => {
                        handlePropertyrequest();
                      }}
                    >
                      {propertyDetails?.is_i_requested?.status === 1 ||
                      propertyDetails?.is_i_requested?.status === 0
                        ? "Requested"
                        : "Request"}
                    </Button>
                  </Box>
                </Box>
              </Box> 
            </Box> */}
          {/* </ScrollAnimation> */}
        </>
      )}
    </Container>
  );
}
