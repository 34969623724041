import React, { useContext, useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Path from "../../../common/Path";
import InputComponent from "../../../component/Input/InputComponentTextField";
import formValidator from "../../../common/Validator";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../../Context/LanguageContext";
import ScrollAnimation from "react-animate-on-scroll";

export default function AgriculturalEvaluation() {
  const theme = useTheme();
  const { t } = useTranslation();
  const { language } = useContext(LanguageContext);
  const navigate = useNavigate();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [validationError, setValidationError] = useState([]);
  const [formDetails, setFormDetails] = useState({
    floorArea: "",
    soilQualityType: "",
    cropYieldHistory: "",
    govtRegulations: "",
    proximity: "",
    demand: "",
    potential: "",
    water: "",
  });

  useEffect(() => {
    window.scrollTo({ behavior: "instant", top: 0 });
  }, []);


  const HandleFormSubmittedDetails = () => {
    try {
      setIsSubmitted(true);
      const validationResult = formValidator(
        formDetails,
        "agriEvalution",
        "",
        t
      );
      if (validationResult.length > 0) {
        return setValidationError(validationResult);
      }
      if (!formDetails.water)
        return toast.error(
          language === "en" ? "select water avalability" : "حدد توافر المياه"
        );

      navigate(Path.PROPERTY_EVALUATION_AGRICULTURAL__CALC);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFormDetails = (e) => {
    const { name, value } = e.target;
    const updatedFormDetails = { ...formDetails, [name]: value };
    setFormDetails(updatedFormDetails);
    if (isSubmitted) {
      const validationResult = formValidator(
        updatedFormDetails,
        "agriEvalution",
        "",
        t
      );
      setValidationError(validationResult);
    }
  };

  return (
    <ScrollAnimation animateIn="fadeIn">
      <div id="resident" style={{ padding: "1rem" }}>
        <Typography
          sx={{
            fontWeight: "600",
            color: theme.palette.primary.main,
          }}
          variant="text"
        >
          {t("eval.msg")}
        </Typography>
        <Box sx={{ pt: "1rem" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: { xs: "0", sm: "1rem" },
            }}
          >
            <Box sx={{ width: { xs: "100%", sm: "50%" } }}>
            
              <Box sx={{ mb: "0.7em", width: "100%" }}>
                <InputComponent
                  type="text"
                  name="floorArea"
                  onChange={handleFormDetails}
                  isError={validationError?.find((x) => {
                    return x.key === "floorArea" ? true : false;
                  })}
                  helperText={
                    validationError.find((x) => x.key === "floorArea")?.message
                  }
                  sx={{
                    bgcolor: theme.palette.secondary.main,
                    width: "100%",
                    borderRadius: "12px",
                  }}
                  id="floorArea"
                  value={formDetails.floorArea}
                  placeholder={t("eval.agr.area")}
                />
              </Box>

              <Box sx={{ mb: "0.7em", width: "100%" }}>
                <InputComponent
                  type="text"
                  name="cropYieldHistory"
                  onChange={handleFormDetails}
                  isError={validationError?.find((x) => {
                    return x.key === "cropYieldHistory" ? true : false;
                  })}
                  helperText={
                    validationError.find((x) => x.key === "cropYieldHistory")
                      ?.message
                  }
                  sx={{
                    bgcolor: theme.palette.secondary.main,
                    width: "100%",
                    borderRadius: "12px",
                  }}
                  id="cropYieldHistory"
                  value={formDetails.cropYieldHistory}
                  placeholder={t("eval.agr.yh")}
                />
              </Box>
            </Box>

            <Box sx={{ width: { xs: "100%", sm: "50%" } }}>
          

              <Box sx={{ mb: "0.7em", width: "100%" }}>
                <InputComponent
                  type="text"
                  name="soilQualityType"
                  onChange={handleFormDetails}
                  isError={validationError?.find((x) => {
                    return x.key === "soilQualityType" ? true : false;
                  })}
                  helperText={
                    validationError.find((x) => x.key === "soilQualityType")
                      ?.message
                  }
                  sx={{
                    bgcolor: theme.palette.secondary.main,
                    width: "100%",
                    borderRadius: "12px",
                  }}
                  id="soilQualityType"
                  value={formDetails.soilQualityType}
                  placeholder={t("eval.agr.qua")}
                />
              </Box>

              <Box sx={{ mb: "0.7em" }}>
                <InputComponent
                  type="text"
                  name="govtRegulations"
                  onChange={handleFormDetails}
                  isError={validationError?.find((x) => {
                    return x.key === "govtRegulations" ? true : false;
                  })}
                  helperText={
                    validationError.find((x) => x.key === "govtRegulations")
                      ?.message
                  }
                  sx={{
                    bgcolor: theme.palette.secondary.main,
                    width: "100%",
                    borderRadius: "12px",
                  }}
                  id="govtRegulations"
                  value={formDetails.govtRegulations}
                  placeholder={t("eval.agr.gr")}
                />
              </Box>
            </Box>
          </Box>

          <Box sx={{ mb: "0.7em" }}>
            <InputComponent
              type="text"
              name="proximity"
              onChange={handleFormDetails}
              isError={validationError?.find((x) => {
                return x.key === "proximity" ? true : false;
              })}
              helperText={
                validationError.find((x) => x.key === "proximity")?.message
              }
              sx={{
                bgcolor: theme.palette.secondary.main,
                width: "100%",
                borderRadius: "12px",
              }}
              id="proximity"
              value={formDetails.proximity}
              placeholder={t("eval.agr.pm")}
            />
          </Box>

          <Box sx={{ mb: "0.7em" }}>
            <InputComponent
              type="text"
              name="demand"
              onChange={handleFormDetails}
              isError={validationError?.find((x) => {
                return x.key === "demand" ? true : false;
              })}
              helperText={
                validationError.find((x) => x.key === "demand")?.message
              }
              sx={{
                bgcolor: theme.palette.secondary.main,
                width: "100%",
                borderRadius: "12px",
              }}
              id="demand"
              value={formDetails.demand}
              placeholder={t("eval.agr.demand")}
            />
          </Box>

          <Box sx={{ mb: "0.7em" }}>
            <InputComponent
              type="text"
              name="potential"
              onChange={handleFormDetails}
              isError={validationError?.find((x) => {
                return x.key === "potential" ? true : false;
              })}
              helperText={
                validationError.find((x) => x.key === "potential")?.message
              }
              sx={{
                bgcolor: theme.palette.secondary.main,
                width: "100%",
                borderRadius: "12px",
              }}
              id="potential"
              value={formDetails.potential}
              placeholder={t("eval.agr.pote")}
            />
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "row",
              },
              justifyContent: {
                xs: "center",
                sm: "space-between",
              },
              ml: "0.5rem",
            }}
          >
            <Box>
              <Box>{t("eval.agr.water.title")}</Box>
              <Box sx={{ mt: "0.5rem", display: "flex", gap: "1rem" }}>
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="water"
                    value={"irrigation"}
                    onChange={handleFormDetails}
                  />
                  <span className="checkmark"></span>
                  {t("eval.agr.water.ir")}
                </label>
                <Box sx={{ display: "inline-block", ml: "1rem" }}>
                  <label className="custom-radio">
                    <input
                      type="radio"
                      name="water"
                      value={"natural sources"}
                      onChange={handleFormDetails}
                    />
                    <span className="checkmark"></span>
                    {t("eval.agr.water.ns")}
                  </label>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              mt: "1rem",
            }}
          >
            <Button
              onClick={HandleFormSubmittedDetails}
              variant="contained"
              sx={{
                borderRadius: "12px",
                padding: "0.3em 5em",
                textTransform: "capitalize",
                fontSize: "1.1rem",
                bgcolor: theme.palette.gold.main,
                mt: "1rem",
                color: "#fff",
              }}
            >
              {t("eval.button")}
            </Button>
          </Box>
        </Box>
      </div>
    </ScrollAnimation>
  );
}
