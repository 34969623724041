import { useTheme } from '@mui/material/styles';
import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useTranslation } from "react-i18next";
import { LocationSVG } from "../../common/Svg";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

function SearchPropertyByLocation({
  searchType,
  p,
  textColor,
  address,
  placeholder,
  handleAddress,
  updateLatLng,
  h,
  placeholderColor,
  label = "Address",
  size = "small",
  disabled = false,
  width,
  className,
  setSearchView
}) {
  const theme = useTheme();
  const { t } = useTranslation();

  const handleSelect = (address) => {
    handleAddress(address);
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => updateLatLng(latLng))
      .catch((error) => console.error("Error", error));
  };

  const typesToSearch = searchType
    ? [searchType]
    : ["geocode", "establishment"];

  return (
    <PlacesAutocomplete
      value={address && address}
      onChange={handleAddress}
      onSelect={handleSelect}
      searchOptions={{
        types: typesToSearch,
      }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <Box
          width={width}
          borderRadius="12px"
          display="flex"
          justifyContent="start"
          alignItems="center"
          py="12px"
          position={"relative"}
        >
          <Box
            className={className || ""}
            sx={{ border: "none", mt: -1, mb: -1 }}
            width="100%"
          >

            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocationSVG color={theme.palette.dark.main} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment onClick={(e) => {
                    setSearchView(false)
                    handleAddress("")
                  }} position="start" sx={{ cursor: "pointer" }} >
                    <CloseRoundedIcon color={theme.palette.dark.main} />
                  </InputAdornment>
                ),
              }}
              type="text"
              id="findByLocation"
              name="findByLocation"
              sx={{
                width: { xs: "100%", sm: "220px" },
                paddingTop: `0 !important`,
                paddingBottom: `0 !important`,
                bgcolor: theme.palette.newBg_footer.main,
                boxShadow: `3px 3px 3px 0px ${theme.palette.mode === "light"
                  ? "rgba(0, 0, 0, 0.15)"
                  : "#DBDBDB25"
                  }`,
                border: `1px solid ${theme.palette.mode === "light"
                  ? "rgba(0, 0, 0, 0.15)"
                  : "#DBDBDB25"
                  }`,
                borderRadius: "12px",
                fontSize: "20px",
                fontWeight: "500",
                " .MuiOutlinedInput-input": {
                },
                "& .MuiOutlinedInput-input": {
                  padding: "8px ",
                },
                "& fieldset": { border: "none" },
              }}
              placeholder={t("property.type.lcBtn")}
              {...getInputProps({
                disabled: disabled,
                placeholder: placeholder || "Enter Address",
                className: "location-search-input",
               
              })}
              InputLabelProps={{
                shrink: true,
              }}
              className="form-control"


            />

            <Box
              sx={{
                width: "100%",
                minWidth: "285px",
                display: suggestions.length ? "block" : "none",
                bgcolor: theme.palette.mode === "light" ? "#FFF" : "#2f2f2f",
                borderRadius: "12px",
                p: 1,
                boxSizing: "border-box",
                zIndex: 99999,
                mt: "0.5rem",
                position: "absolute",
                fontSize: "22px",
                fontFamily: "Roboto",
              }}
            >
              {loading && <div>Loading...</div>}
              {suggestions?.map((suggestion) => {
                const className = suggestion?.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                return (
                  <Box
                    sx={{
                      color: textColor,
                      textAlign: "left",
                      cursor: "pointer",
                      borderBottom: "solid 0.01px",
                      borderBottomColor: "divider",
                      fontSize: "1rem",
                      p: "1rem 0",
                    }}
                    width={"100%"}
                    key={suggestion?.description}
                    {...getSuggestionItemProps(suggestion, {
                      className,
                    })}
                  >
                    <Typography
                      sx={{
                        color:
                          theme.palette.mode === "light"
                            ? `${suggestion?.active ? "#1f243870" : "#1f2438"}`
                            : `${suggestion?.active ? "#ffffff70" : "#fff"}`,
                      }}
                      variant="subtitle2"
                    >
                      {suggestion?.description}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
      )}
    </PlacesAutocomplete>
  );
}

export default SearchPropertyByLocation;
