import React, { useContext, useState } from "react";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import UserProfileUpdateDialogue from "./UserProfileUpdateDropDown";
import { useNavigate } from "react-router-dom";
import Path from "../../../common/Path";
import UserLogoutDialogue from "./UserLogoutDialogue";
import UserDeleteDialogue from "./UserDeleteDialogue";
import { baseUrl } from "../../../common/Constant";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../../Context/LanguageContext";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
export default function UserDetailsDropDown(props) {
  const {
    toggleDrawer,
    isDrawerOpen,
    themeMode,
    setUserLogoutAction,
    logoutLogoutAction,
    handleLogOut,
    handleDeleteUserAccount,
    userInfo,
    setuserInfo,
    setuserProfileInfo,
  } = props;
  const navigate = useNavigate();
  const theme = useTheme();
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const { t } = useTranslation();
  const { language } = useContext(LanguageContext);

  const [isImageError, setIsImageError] = useState(false);

  const handleToggleDrawer = () => {
    toggleDrawer();
    setOverlayVisible(!isDrawerOpen);
  };
  const firstLetter = userInfo?.f_name
    ? userInfo?.f_name.at(0)?.toUpperCase()
    : userInfo?.company_name?.at(0)?.toUpperCase();

  const renderUserProfileImage = () => {
    if (isImageError) {
      return (
        <Box
          width="100%"
          height="100%"
          borderRadius="50%"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "2rem",
            fontWeight: "600",
            opacity: "0.7",
          }}
        >
          {firstLetter}
        </Box>
      );
    } else {
      const imageUrl =
        userInfo && userInfo?.role === 2 && userInfo?.agency_logo
          ? `${baseUrl}/uploads/agency/logo/${userInfo?.agency_logo}`
          : userInfo?.profile && `${baseUrl}/uploads/user/profile/${userInfo?.profile}`;

      return (
        <img
          style={{ borderRadius: "50%", objectFit: "cover" }}
          height="100%"
          width="100%"
          src={imageUrl}
          alt={userInfo?.profile ? "User Profile Image" : "Default User Image"}
          onError={() => {
            setIsImageError(true);
          }}
        />
      );
    }
  };

  return (
    <div>
      <div>
        {isOverlayVisible && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              backdropFilter: "blur(3px)",
              zIndex: 0,
            }}
            onClick={handleToggleDrawer}
          ></div>
        )}

        <div
          style={{
            width: "50px",
            padding: "1px",
            position: "relative",
            cursor: "pointer",
            // direction: language === "ar" ? "rtl" : "ltr",
          }}
          id="profile-drawer-controler"
          onClick={handleToggleDrawer}
        >
          <div
            style={{
              display: "flex",
              zIndex: 9,
            }}
            id="profile-drawer-controler"
            onClick={toggleDrawer}
          >
            <div
              style={{
                backgroundColor: "#dbdbdb",
                borderRadius: "50%",
                width: "36px",
                height: "36px",
                display: "flex",
                justifyContent: "center",
                border: "1px solid #1f2438",
              }}
            >
              {userInfo && renderUserProfileImage()}
            </div>
          </div>
        </div>

        <div
          id="profile-drawer"
          style={{
            color: "#1f2438",
            fontSize: "16px",
            boxShadow: " 0px 3px 10px 1px #1f1834",
            position: "absolute",
            padding: "1.3rem",
            top: "4.5rem",
            right: "3.5rem",
            backgroundColor: "white",
            borderRadius: "12px",
            // border:"1px solid red",
            minWidth: "250px",
            display: isDrawerOpen ? "block" : "none",
          }}
          className={`profile-drawer ${isDrawerOpen ? "open" : ""}`}
        >
          <Box
            sx={{
              display: "flex",
              mb: "9px",
              fontSize: "1.1rem",
              // mr: "10px",
              gap: "1rem",
              direction: language === "ar" ? "rtl" : "ltr",
            }}
          >
            <Box>
              {" "}
              <div
                style={{
                  borderRadius: "7px",
                  height: "55px",
                  width: "55px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid #0000003b",
                  position: "relative",
                }}
              >
                {isImageError ? (
                  <Box
                    width="100%"
                    height={"100%"}
                    borderRadius={"50%"}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "2rem",
                      fontWeight: "600",
                      opacity: "0.7",
                    }}
                  >
                    {firstLetter}
                  </Box>
                ) : (
                  <img
                    style={{
                      borderRadius: "6px",
                    }}
                    height={"100%"}
                    width={"100%"}
                    src={
                      userInfo && userInfo?.role === 2
                        ? userInfo?.agency_logo
                          ? `${baseUrl}/uploads/agency/logo/${userInfo?.agency_logo}`
                          : "/assets/image/np.png"
                        : userInfo?.profile
                        ? `${baseUrl}/uploads/user/profile/${userInfo?.profile}`
                        : "/assets/image/np.png"
                    }
                    // src={"/assets/image/User.svg"}
                    alt=""
                    srcSet=""
                    onError={() => {
                      setIsImageError(true);
                    }}
                  />
                )}
              </div>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {userInfo?.f_name
                ? `${userInfo?.f_name} ${userInfo?.l_name}`
                : userInfo?.company_name}
            </Box>
          </Box>
          <Divider sx={{ m: "10px 0px", bgcolor: theme.palette.divider }} />
          <Box
            sx={{
              display: userInfo && userInfo?.role === 2 ? "none" : "flex",
              alignItems: "center",
              mb: "9px",
              cursor: "pointer",
              gap: "1rem",
              direction: language === "ar" ? "rtl" : "ltr",
            }}
          >
            <Box>
              <img src="/assets/image/property/profile.svg" alt="" srcSet="" />{" "}
            </Box>
            <Box sx={{ cursor: "pointer" }}>
              <UserProfileUpdateDialogue
                themeMode={themeMode}
                handleToggleDrawer={handleToggleDrawer}
                userInfo={userInfo}
                setuserInfo={setuserInfo}
                setuserProfileInfo={setuserProfileInfo}
              />
            </Box>
          </Box>
          <Divider
            sx={{
              display: userInfo && userInfo?.role === 2 ? "none" : "block",
              m: "10px 0px",
              bgcolor: theme.palette.divider,
            }}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: "9px",
              cursor: "pointer",
              gap: "1rem",
              direction: language === "ar" ? "rtl" : "ltr",
            }}
            onClick={() => {
              navigate(Path.PROPERTY_HISTORY);
              handleToggleDrawer();
            }}
          >
            <Box>
              <img src="/assets/image/property/history.svg" alt="" srcSet="" />{" "}
            </Box>
            <Typography>{t("navbar.menu.userProfile.history")}</Typography>
          </Box>
          <Divider sx={{ m: "10px 0px", bgcolor: theme.palette.divider }} />

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: "9px",
              cursor: "pointer",
              gap: "1rem",
              direction: language === "ar" ? "rtl" : "ltr",
            }}
            onClick={() => {
              navigate(Path.REQUESTED_PROPERTY);
              handleToggleDrawer();
            }}
          >
            <Box>
              <FavoriteBorderIcon />
            </Box>
            <Typography>{t("navbar.menu.userProfile.myWishlist")}</Typography>
          </Box>
          <Divider sx={{ m: "10px 0px", bgcolor: theme.palette.divider }} />

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: "9px",
              cursor: "pointer",
              gap: "1rem",
              direction: language === "ar" ? "rtl" : "ltr",
            }}
            onClick={() => {
              navigate(Path.REQUESTE_TO_USERS_PROPERTY);
              handleToggleDrawer();
            }}
          >
            <Box>
              <img
                src="/assets/image/property/his.png"
                height={21}
                alt=""
                srcSet=""
              />{" "}
            </Box>
            <Typography>{t("navbar.menu.userProfile.req2yProp")}</Typography>
          </Box>
          <Divider sx={{ m: "10px 0px", bgcolor: theme.palette.divider }} />

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: "9px",
              cursor: "pointer",
              gap: "1rem",
              direction: language === "ar" ? "rtl" : "ltr",
            }}
          >
            <Box>
              <img src="/assets/image/property/login.svg" alt="" srcSet="" />{" "}
            </Box>
            <Box>
              <UserLogoutDialogue
                themeMode={themeMode}
                handleToggleDrawer={handleToggleDrawer}
                setUserLogoutAction={setUserLogoutAction}
                logoutLogoutAction={logoutLogoutAction}
                handleLogOut={handleLogOut}
                setIsImageError={setIsImageError}
              />
            </Box>
          </Box>
          <Divider sx={{ m: "10px 0px", bgcolor: theme.palette.divider }} />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: "9px",
              cursor: "pointer",
              gap: "1rem",
              direction: language === "ar" ? "rtl" : "ltr",
            }}
          >
            <Box>
              <img src="/assets/image/property/delete.svg" alt="" srcSet="" />{" "}
            </Box>
            <UserDeleteDialogue
              themeMode={themeMode}
              handleToggleDrawer={handleToggleDrawer}
              handleDeleteUserAccount={handleDeleteUserAccount}
            />
          </Box>
        </div>
      </div>
    </div>
  );
}
