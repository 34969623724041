import { Box, useMediaQuery, Modal, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import NotificationsIcon from "@mui/icons-material/Notifications";
import apiHelper from "../../common/API/ApiHelper";
import { useContext, useEffect, useState } from "react";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { baseUrl } from "../../common/Constant";
import moment from "moment";
import Path from "../../common/Path";
import { useTheme } from "@mui/material/styles";
import { LanguageContext } from "../../Context/LanguageContext";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export default function NotificationModal({ color }) {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setloading] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);

  const theme = useTheme();
  const MobileView = useMediaQuery("(max-width:900px)");
  const navigate = useNavigate();
  const { language } = useContext(LanguageContext);
  const { t } = useTranslation();

  const handleClose = () => setOpen(false);

  const getData = async () => {
    try {
      setloading(true);
      const res = await apiHelper.GetNotification();
      const newData = await Promise.all(
        res?.data?.r?.map(async (item) => {
          const img = await FetchPropertyImage(item?.type_id);
          const date = moment(item?.created_at).from(new Date(Date.now()));
          return { ...item, date: date, image: img && img.img };
        })
      );
      setData(newData);
      setloading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    //eslint-disable-next-line
  }, []);

  const handleOpen = () => {
    if (MobileView) {
      navigate(Path.NOTIFICATION);
    } else {
      setOpen(true);
      getData();
    }
  };

  const style = {
    position: "absolute",
    top: "5rem",
    right: "5rem",
    width: 400,
    bgcolor: "#FFF",
    boxShadow: 24,
    borderRadius: "12px",
    outline: "none",
    p: 2,
    maxHeight: `calc(100vh - 20rem)`,
    overflow: "auto",
  };

  const handleRequst = async (is_accept, user_req_id) => {
    try {
      setIsActionLoading(true);
      const data = {
        is_accept: is_accept,
        user_req_id: user_req_id,
      };
      await apiHelper.PropertyChatRequest(data);

      setData((data) =>
        data?.map((x) => {
          if (x?.user_req_id === user_req_id) {
            return { ...x, is_accept: is_accept === 1 ? 1 : -1 };
          } else {
            return x;
          }
        })
      );
      setIsActionLoading(false);
    } catch (error) {
      setIsActionLoading(false);
      console.log(error);
    }
  };

  const FetchPropertyImage = async (id) => {
    try {
      const res = await apiHelper.getPropertyDetailsByID(id);
      return { img: res?.data?.r?.image[0] && res?.data?.r.image[0]?.image };
      // setimage(res.data.r.image[0].image);
    } catch (error) {
      console.log(error);
    }
  };

  const handleNav = (id) => {
    navigate(`${Path.PROPERTY_DETAILS}/${id}`);
    handleClose();
  };

  return (
    <div>
      <Box
        onClick={handleOpen}
        sx={{ position: "relative", cursor: "pointer" }}
      >
        {MobileView ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "1rem",
              alignItems: "center",
            }}
          >
            <NotificationsIcon
              sx={{
                color: color,
              }}
            />{" "}
            {/* Notification */}
            {t("notf.title")}
          </Box>
        ) : (
          <NotificationsIcon
            sx={{
              color: color,
            }}
          />
        )}
        {/* <div
          style={{
            position: "absolute",
            top: "-7px",
            right: "-5px",
            color: "#eee",
            borderRadius: "50%",
            padding: "3px 5px",
            backgroundColor: "#8c1818",
            fontSize: "0.8rem",
          }}
          className="noti"
        >
          new
        </div> */}
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            textAlign={"center"}
            variant="h6"
            component="h2"
          >
            {t("notf.title")}
          </Typography>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              <CircularProgress style={{ color: "#1f2438" }} />
            </Box>
          ) : data.length === 0 && !loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                m: "1rem",
              }}
            >
              {t("notf.NoData")}
            </Box>
          ) : (
            data &&
            data?.map((item) => (
              <>
                <Box
                  sx={{
                   
                    display: "flex",
                    alignItems: "center",
                    mt: 1,
                    gap: "1rem",
                    boxShadow: `2px 2px 1px 0px ${
                      theme.palette.mode === "light"
                        ? "rgba(0, 0, 0, 0.25)"
                        : "#DBDBDB22"
                    }`,
                    borderRadius: "12px",
                    padding: "0.5rem",
                    cursor: "pointer",
                  }}
                >
                  <Box sx={{ width: "70px", height: "50px" }}>
                    <img
                      src={
                        item?.image === undefined
                          ? "/assets/image/home/home.jpg"
                          : `${baseUrl}/uploads/property/image/${item?.image}`
                      }
                      alt="property"
                      style={{
                        width: "70px",
                        height: "50px",
                        objectFit: "cover",
                        borderRadius: "12px",
                        boxShadow: "-5px -5px 6px -4px grey",
                      }}
                    />
                  </Box>
                  <Box sx={{ width: `calc(100% - 70px)` }}>
                    <Box key={item?.id} id="modal-modal-description">
                      <Box
                        sx={{
                          "&:hover": {
                            fontWeight: "600",
                          },
                        }}
                        onClick={() => {
                          item.is_property_delete === 1
                            ? handleNav(item.type_id)
                            : toast.warn(`${t("notf.propertyAvailable")}`);
                        }}
                      >
                        {" "}
                        {language === "ar" ? item?.ar_msg : item?.message}
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          color: "#828282",
                        }}
                      >
                        <Typography style={{ fontSize: "0.85rem" }}>
                          {item?.date}.
                        </Typography>
                        <Typography
                          sx={{
                            display: item?.message.includes("Requested by")
                              ? "flex"
                              : "none",
                            color:
                              (item?.is_accept === 1 && "#014a01") ||
                              (item?.is_accept === 0 && "#828282") ||
                              (item?.is_accept === -1 && "#760505"),
                          }}
                          style={{ fontSize: "0.85rem" }}
                        >
                          {item?.is_accept === 1
                            ? `${t("notf.accepted")}`
                            : item?.is_accept === -1 && `${t("notf.reject")}`}
                        </Typography>
                        <Box
                          sx={{
                            display:
                              item?.message.includes("Requested by") &&
                              item?.is_accept === 0
                                ? "flex"
                                : "none",
                            gap: "1rem",
                          }}
                        >
                          <CheckCircleOutlinedIcon
                            style={{
                              color: "green",
                              fontSize: "1.75rem",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              !isActionLoading &&
                                handleRequst(1, item?.user_req_id);
                            }}
                          />
                          <HighlightOffOutlinedIcon
                            style={{
                              color: "red",
                              fontSize: "1.75rem",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              !isActionLoading &&
                                handleRequst(0, item?.user_req_id);
                            }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </>
            ))
          )}
        </Box>
      </Modal>
    </div>
  );
}
