import { Box, useTheme } from "@mui/system";
import React, { useState, useEffect, useRef, useTransition } from "react";
import { Checkbox, IconButton, Typography } from "@mui/material";
import {
  BadSVG,
  BASEMENTSVG,
  BathSVG,
  CarSVG,
  DATESVG,
  EMAILSVG,
  FLOORSVG,
  LocationSVG,
  PhoneSVG,
  SMSSVG,
  SQFTSVG,
  WhatsappSVG,
} from "../../../../common/Svg";
import { baseUrl } from "../../../../common/Constant";
import wpImg from "../../../../common/assets/image/wp.png";
import smsImg from "../../../../common/assets/image/sms.png";
import defaultImg from "../../../../common/assets/image/User.svg";
import moment from "moment/moment";
import { Favorite, FavoriteBorder, Label } from "@mui/icons-material";
import apiHelper from "../../../../common/API/ApiHelper";
import { toast } from "react-toastify";

const DetailsComponent = ({
  propertyDetails,
  CurrentUserInfo,
  setPropertyDetails,
}) => {
  const theme = useTheme();
  const [current, setCurrent] = useState(1);
  const sectionsRef = useRef(new Map());
  const sections = [
    { id: 1, title: "Summary" },
    { id: 2, title: "Details" },
    { id: 3, title: "Location" },
    { id: 4, title: "Contact" },
  ];

  const agencyList =
    propertyDetails &&
    propertyDetails?.agency?.map((x) => x?.company_name ?? "NA");

  const detailsArray = [
    {
      icon: <BadSVG color={theme.palette.dark.main} />,
      title: "Bedroom",
      data: propertyDetails?.no_bed,
    },
    {
      icon: <BathSVG color={theme.palette.dark.main} />,
      title: "Bath",
      data: propertyDetails?.no_bath,
    },
    {
      icon: <CarSVG color={theme.palette.dark.main} />,
      title: "Parking",
      data: propertyDetails?.no_parking,
    },
    {
      icon: <FLOORSVG color={theme.palette.dark.main} />,
      title: "Floor",
      data: propertyDetails?.no_floor ?? "NA",
    },
    {
      icon: <BASEMENTSVG color={theme.palette.dark.main} />,
      title: "Basement",
      data: propertyDetails?.no_basement ?? "NA",
    },
    {
      icon: <SQFTSVG color={theme.palette.dark.main} />,
      title: "Sq. Ft.",

      data: propertyDetails?.area ?? "NA",
    },
    {
      icon: <DATESVG color={theme.palette.dark.main} />,
      title: "Listed",

      data: moment(propertyDetails?.created_at).format("DD/MM/YYYY"),
    },
  ];

  const handleScroll = (id) => {
    const sectionElement = sectionsRef.current.get(id);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleWishlistClick = async (id) => {
    try {
      apiHelper.updateWishlist(id);
      setPropertyDetails((prev) => {
        return {
          ...prev,
          is_favorite: prev.is_favorite === 1 ? 0 : 1,
        };
      });
    } catch (error) {}
  };

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const section = sections.find(
            (section) => section.title === entry.target.id
          );
          if (section) {
            setCurrent(section.id);
          }
        }
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    sectionsRef.current.forEach((sectionElement) => {
      if (sectionElement) {
        observer.observe(sectionElement);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, [sections]);
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  return (
    <Box sx={{ height: "calc(100vh - 260px)" }}>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      >
        {/* Header */}
        <Box
          sx={{
            // fontSize: "20px",
            borderRadius: "30px",
            background: theme.palette.primary.main,
            display: "flex",
            alignItems: "center",
            gap: 2,
            width: { xs: "100%", md: "fit-content" },
            overflow: "auto",
            mb: 1,
          }}
        >
          {sections?.map((section, index) => {
            if (CurrentUserInfo?.role === 3 && index === 3) return null;
            return (
              <Box
                key={section?.id}
                sx={{
                  fontSize: { sm: "18px", md: "20", xl: "24px" },
                  borderRadius: "30px",
                  background:
                    current === section?.id
                      ? theme.palette.activeTab.main
                      : theme.palette.primary.main,
                  padding: "15px 20px",
                  fontWeight: current === section?.id ? "600" : "400",
                  color: theme.palette.light.main,
                  cursor: "pointer",
                  "&:hover": {
                    background: theme.palette.activeTab.main,
                  },
                }}
                onClick={() => {
                  setCurrent(section.id);
                  handleScroll(section.id);
                }}
                aria-current={current === section?.id ? "page" : undefined}
              >
                {section?.title}
              </Box>
            );
          })}
        </Box>

        <Checkbox
          {...label}
          icon={<FavoriteBorder sx={{ fontSize: "32px" }} />}
          checkedIcon={<Favorite sx={{ fontSize: "38px" }} />}
          checked={propertyDetails?.is_favorite == 1 ? true : false}
          onClick={() => handleWishlistClick(propertyDetails?.id)}
          sx={{
            width: 60,
            height: 60,
          }}
        />
      </Box>
      {/* Data listing */}
      <Box
        sx={{
          overflow: { md: "auto" },
          height: { md: "calc(100vh - 340px)" },
          display: "flex",
          flexDirection: "column",
          gap: 3,
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        <div
          id={"Summary"}
          ref={(el) => sectionsRef.current.set(1, el)}
          aria-labelledby={`section-${1}`}
        >
          <Box>
            <Box>
              <h2>Snap Up This Great Investment</h2>
              <Box sx={{ mt: "0.1rem", width: { xs: "100%", md: "75%" } }}>
                <Typography>{propertyDetails?.description}</Typography>

                <Box
                  sx={{
                    mt: "0.5rem",
                    display:
                      propertyDetails?.major_road === "1" ? "block" : "block",
                  }}
                >
                  <Typography>
                    <span style={{ fontWeight: "bolder" }}>
                      Major road access :
                    </span>{" "}
                    {propertyDetails?.major_road === "1" ? "Yes" : "No"}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    mt: "0.5rem",
                    display:
                      propertyDetails?.renovation === "1" ? "block" : "block",
                  }}
                >
                  <Typography>
                    <span style={{ fontWeight: "bolder" }}>
                      Recently renovated :
                    </span>{" "}
                    {propertyDetails?.renovation === "1" ? "Yes" : "No"}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </div>

        <div
          id={"Details"}
          ref={(el) => sectionsRef.current.set(2, el)}
          aria-labelledby={`section-${2}`}
        >
          <h2>Details</h2>
          <Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 2,
              }}
            >
              {detailsArray?.map(({ data, icon, title }, i) => (
                <Box key={i} sx={{ width: { md: "calc(18% - 16px)" }, m: 2 }}>
                  <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                    <Box>{icon}</Box>
                    <Box>
                      <Typography>{title}</Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: 600, fontSize: "25px" }}
                    >
                      {data !== undefined ? data : "N/A"}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </div>

        <div
          id={"Location"}
          ref={(el) => sectionsRef.current.set(3, el)}
          aria-labelledby={`section-${3}`}
        >
          <h2>Location</h2>
          <Typography
            variant="smallText"
            sx={{
              display: "flex",
              ml: "0.5rem",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <span>
              <LocationSVG color={theme.palette.dark.main} />
            </span>
            &nbsp;
            <Box
              sx={{
                cursor: "pointer",
                "&:hover": {
                  textDecoration: "underline",
                  color: "#00009d",
                },
              }}
              //   onClick={() => {
              //     window.open(
              //       "https://www.google.com/maps/search/" +
              //         propertyDetails?.location
              //     );
              //   }}
            >
              {propertyDetails?.location}
            </Box>
          </Typography>
          <Box sx={{ width: "calc(100%)", height: "400px", mt: 2 }}>
            {/* <iframe
                            width="100%"
                            height="100%"
                            style={{ border: 0 }}
                            loading="lazy"
                            allowFullScreen
                            referrerPolicy="no-referrer-when-downgrade"
                            src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDQA_QHDdWzhTzvutj_tV6IVGoyIu1PXBM&q=${encodeURIComponent(
                                propertyDetails?.location
                            )}`}
                            title="Property Location"
                        ></iframe> */}

            <iframe
              title="embed"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3669.541058138732!2d72.53870307588336!3d23.11389046291316!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e82dd003ff749%3A0x359e803f537cea25!2sGANESH%20GLORY%2C%20Gota%2C%20Ahmedabad%2C%20Gujarat%20382481!5e0!3m2!1sen!2sin!4v1725363275433!5m2!1sen!2sin"
              width="100%"
              height="100%"
              allowFullScreen="true"
              style={{ border: 0, borderRadius: "9px" }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </Box>
        </div>

        <div
          id={"Contact"}
          ref={(el) => sectionsRef.current.set(4, el)}
          aria-labelledby={`section-${4}`}
          style={{ display: CurrentUserInfo?.role === 3 ? "none" : "block" }}
        >
          <h2>Contact</h2>
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <Box sx={{ height: "60px", width: "60px" }}>
              {" "}
              <img
                src={
                  propertyDetails?.user?.profile === null
                    ? defaultImg
                    : `${baseUrl}/uploads/user/profile/${propertyDetails?.user?.profile}`
                }
                style={{ aspectRatio: "1/1", borderRadius: "12px" }}
                width={"100%"}
                height={"100%"}
                alt=""
                srcSet=""
              />
            </Box>
            <Box>
              <Box>
                {" "}
                <Typography sx={{ fontWeight: "500" }}>
                  {propertyDetails?.user?.role === 2
                    ? `${propertyDetails?.user?.company_name}`
                    : `${propertyDetails?.user?.f_name} ${propertyDetails?.user?.l_name}`}
                </Typography>
              </Box>
              <Box>
                <Typography sx={{ color: theme.palette.dark.light }}>
                  {propertyDetails?.user?.role === 1
                    ? "Agent"
                    : propertyDetails?.user?.role === 2
                    ? "Agency"
                    : propertyDetails?.user?.role === 3 && "Customer"}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              border: `0.5px solid ${theme.palette.dark.main}`,
              padding: 2,
              my: 2,
              borderRadius: "10px",
              gap: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: 2,
                overflow: "scroll",
              }}
            >
              <a
                style={{
                  textDecoration: "none",
                  color: theme.palette.dark.main,
                  textOverflow: "ellipsis",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: 2,
                }}
                href={`mailto:${propertyDetails?.user?.email}`}
              >
                <EMAILSVG color={theme.palette.dark.main} /> &nbsp;&nbsp;&nbsp;
                {propertyDetails?.user?.email}
              </a>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: 2,
              }}
            >
              <PhoneSVG color={theme.palette.dark.main} />
              <a
                style={{
                  textDecoration: "none",
                  color: theme.palette.dark.main,
                }}
                href={`tel:${propertyDetails?.user?.phone_no}`}
              >
                {propertyDetails?.user?.phone_no}
              </a>
            </Box>
            <Box
              sx={{
                display:
                  propertyDetails?.user?.agency_description ||
                  propertyDetails?.user?.agent_description
                    ? "flex"
                    : "none",
                justifyContent: "start",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Typography>
                {" "}
                <span style={{ fontWeight: "bolder" }}>Details : </span>
                {`${
                  (propertyDetails?.user?.agency_description ||
                    propertyDetails?.user?.agent_description) ??
                  "NA"
                }`}{" "}
              </Typography>
            </Box>
            <Box
              sx={{
                display: propertyDetails?.user?.role === 1 ? "flex" : "none",
                justifyContent: "start",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Box>
                <Typography>
                  <span style={{ fontWeight: "bolder" }}>Agency : </span>
                  {agencyList && agencyList?.map((x) => x)}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              gap: 2,
              mb: 2,
            }}
          >
            <a href={`sms:${propertyDetails?.user?.phone_no}`}>
              {/* <img
                src={smsImg}
                alt=""
                style={{ height: "44px", width: "44px" }}
              /> */}
              <SMSSVG
                color1={theme.palette.dark.main}
                color2={theme.palette.light.main}
              />
            </a>
            <a
              href={`https://wa.me/${propertyDetails?.user?.phone_no}`}
              target="_blank"
              rel="noreferrer"
            >
              {/* <img
                src={wpImg}
                alt=""
                style={{ height: "44px", width: "44px" }}
              /> */}
              <WhatsappSVG
                color1={theme.palette.dark.main}
                color2={theme.palette.light.main}
              />
            </a>
          </Box>
        </div>
      </Box>
    </Box>
  );
};

export default DetailsComponent;
