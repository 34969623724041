import { Box, useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import Path from "../../common/Path";
import ViewHeadlineOutlinedIcon from "@mui/icons-material/ViewHeadlineOutlined";
import NavbarDrawer from "./NavbarDrawer";
import "./navStyle.css";
// import CustomerLoginDialogue from "../../screen/SignIn/CustomerLoginDialogue";
import UserDetailsDropDown from "./user/UserDetailsDropDown";
import CustomizedSwitches from "./ModeToggleSwitch/ModeToggleSwitch";
import { ChangeLanguageSVG } from "../../common/Svg";
import apiHelper from "../../common/API/ApiHelper";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../Context/LanguageContext";
import NotificationModal from "../../screen/Notification/NotificationModal";
import { getFirebaseToken } from "../../common/Firebase";
import ChatBedge from "./ChatBedge";
import SignUpComponent from "./SignUpComponent";

export default function NewNavbar(props) {
  const {
    userInfo,
    setuserInfo,
    setThemeMode,
    themeMode,
    handleOpen,
    setOpenLoginModal,
    openLoginModal,
    setGoogleLoginData,
    SetIsLoginGuest,
    isLogInGuest,
    setUserProfileFunctions,
  } = props;
  const { i18n, t } = useTranslation();
  const { setLanguage, language, NewChatCount } = useContext(LanguageContext);
  const navigate = useNavigate();

  const [isScroled, setisScroll] = useState(false);
  const location = useLocation();
  const theme = useTheme();

  const [userProfileInfo, setuserProfileInfo] = useState();

  const [isVisible, setIsVisible] = useState(false);
  const [currentURL, setCurrentURL] = useState(location.pathname);
  const [logoutLogoutAction, setUserLogoutAction] = useState(false);
  const [drawerState, setDrawerState] = React.useState({
    left: false,
  });

  const languages = [
    { title: "English", code: "en" },
    { title: "عربي", code: "ar" },
  ];
  let userInfoLocal;
  try
  {
    userInfoLocal = JSON.parse(localStorage.getItem("userInfo")) ?? null;
  } catch (error)
  {
    userInfoLocal = null;
  }

  const handleLanguageChange = async (lang) => {
    try
    {
      const cd = lang === "ar" ? 2 : 1; //eslint-disable-next-line
      const res = await apiHelper.updateLanguageChange(cd);
    } catch (error) { }
  };

  // fn for user Login Modal

  //--------------------------User profile drawer state and function--------------------------
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  useEffect(() => {
    // const userInfoLocal = JSON.parse(localStorage.getItem("userInfo"));
    let userInfoLocal;
    try
    {
      userInfoLocal = JSON.parse(localStorage.getItem("userInfo"));
    } catch (error)
    {
      userInfoLocal = null;
    }
    if (
      !userInfoLocal ||
      (userInfoLocal.role === 4 &&
        userInfoLocal.email === "guestuser@gmail.com") ||
      userInfoLocal.status === 2 ||
      userInfoLocal.status === -1 ||
      userInfoLocal.status === 0 ||
      (userInfoLocal.role === 1 && userInfoLocal.agency.length === 0)
    )
    {
      SetIsLoginGuest(true);
    } else
    {
      SetIsLoginGuest(false);
      setuserProfileInfo(userInfoLocal);
    } //eslint-disable-next-line
  }, [userInfo]);

  //-------------------------- Menu list handling for different screens --------------------------
  const listStyleLink = {
    textDecoration: "none",
    listStyle: "none",
    color:
      location.pathname === Path.HOME && themeMode === "light" && !isScroled
        ? "#EEE"
        : theme.palette.primary.main,
  };

  const listStyleLI = {
    display: isVisible ? "none" : "block",
    listStyle: "none",
    padding: "0 15px",
    fontWeight: "400",
  };

  const listStyleLIForProperty = {
    display: isVisible ? "block" : "none",
    listStyle: "none",
    padding: "0 15px",
    fontWeight: "400",
  };

  window.addEventListener("scroll", async function () {
    if (window.scrollY > 100 && currentURL === Path.HOME)
    {
      setisScroll(true);
    } else
    {
      setisScroll(false);
    }
  });

  const menuItems = [
    {
      to: Path.HOME,
      className: "menuList11",
      label: `${t("navbar.menu.home")}`,
    },
    {
      to: Path.ABOUT,
      className: "menuList21",
      label: `${t("navbar.menu.about")}`,
    },
    {
      to: Path.PROPERTY,
      className: "menuList81",
      label: `${t("navbar.menu.propertyS")}`,
    },
    {
      to: Path.CONTACT,
      className: "menuList31",
      label: `${t("navbar.menu.contact")}`,
    },
    {
      to: Path.HELP,
      className: "menuList41",
      label: `${t("navbar.menu.help")}`,
    },
  ];

  const propertyItems = [
    {
      to: Path.HOME,
      className: "menuList11",
      label: `${t("navbar.menu.home")}`,
    },
    {
      to: Path.PROPERTY,
      className: "menuList81",
      label: `${t("navbar.menu.propertyS")}`,
    },
    {
      to: Path.ADD_PROPERTY,
      className: "menuList61",
      label: `${t("navbar.menu.property")}`,
    },
    {
      to: Path.CHAT,
      className: "menuList71",
      label: `${t("navbar.menu.chat")}`,
    },
  ];

  useEffect(() => {
    const excludedPaths = [
      Path.HOME,
      Path.ABOUT,
      Path.CONTACT,
      Path.HELP,
      Path.PROPERTY_EVALUATION,
      Path.PROPERTY_EVALUATION_RESIDENCE_CALC,
      Path.PROPERTY_EVALUATION_AGRICULTURAL,
      Path.PROPERTY_EVALUATION_AGRICULTURAL__CALC,
      Path.PROPERTY_EVALUATION_COMMERCIAL,
      Path.PROPERTY_EVALUATION_COMMERCIAL__CALC,
      Path.PROPERTY_EVALUATION_INDUSTRIAL,
      Path.PROPERTY_EVALUATION_INDUSTRIAL__CALC,
    ];

    const HideLiWhile = !excludedPaths.includes(location.pathname);
    setIsVisible(HideLiWhile);
    setCurrentURL(location.pathname);
  }, [location.pathname]);

  const fetchUserInfo = async () => {
    try
    {
      const res = await apiHelper.loginUserDetails();
      res?.data?.s === 1 && res?.data?.r && setuserInfo(res?.data?.r);
    } catch (error)
    {
      console.log(error);
    }
  };

  useEffect(() => {
    userInfoLocal && fetchUserInfo(); //eslint-disable-next-line
  }, []);

  const handleLogOut = async () => {
    const dId = localStorage.getItem("deviceID");
    await apiHelper.SET_FCM_token({
      token: "0",
      device_id: dId,
    });
    localStorage.removeItem("userInfo");
    localStorage.removeItem("userId");
    localStorage.removeItem("deviceID");
    localStorage.removeItem("token");
    setuserProfileInfo(null);
    SetIsLoginGuest(true);
    setuserInfo(null);
    navigate(Path.HOME);
    toast.success(
      language === "en" ? "Logout successfully" : "تسجيل الخروج بنجاح"
    );
    return "done";
  };

  const handleDeleteUserAccount = async () => {
    try
    {
      getFirebaseToken("remove");
      const res = await apiHelper.DeleteUserAccount();
      if (res?.data?.s === 1)
      {
        localStorage.removeItem("token");
        localStorage.removeItem("userInfo");
        localStorage.removeItem("deviceID");
        localStorage.removeItem("userId");
        setuserProfileInfo(null);
        SetIsLoginGuest(true);
        toast.success(
          language === "en"
            ? "Account deleted successfully"
            : "تم حذف الحساب بنجاح"
        );
        navigate(Path.HOME);
      } else
      {
        toast.error(res?.data?.m);
      }
    } catch (error)
    {
      error?.response?.data?.message
        ? console.log(error?.response?.data?.message)
        : console.log(error?.message);
    }
  };

  return (
    <nav
      id="navbar"
      style={{
        display:
          location.pathname === Path.PROPERTY_HISTORY ||
            location.pathname === Path.SELL_PROPERTY_HISTORY ||
            location.pathname === Path.RENT_PROPERTY_HISTORY ||
            location.pathname === Path.REQUESTED_PROPERTY ||
            location.pathname === Path.REQUESTE_TO_USERS_PROPERTY ||
            location.pathname === Path.USER_PROFILE ||
            location.pathname === Path.APPROVAL ||
            location.pathname === Path.AGENCY_NOT_FOUND ||
            location.pathname === Path.REJECT ||
            location.pathname === Path.DELETE
            ? "none"
            : "block",
        position: "fixed",
        width: "100%",
        boxSizing: " border-box",
        padding: "11px 0",
        top: "0px",
        zIndex: 2,
        height: "95px",
        backgroundColor:
          location.pathname === Path.HOME
            ? isScroled
              ? theme.palette.navbarBG.main
              : "transparent"
            : theme.palette.navbarBG.main,
      }}
    >
      <Box sx={{ width: "100%" }}>
        <Box>
          {/*---------------------------------------- Navbar Drawer component*----------------------------------------*/}
          <Box sx={{ display: "none" }}>
            <NavbarDrawer
              languages={languages}
              themeMode={themeMode}
              drawerState={drawerState}
              setDrawerState={setDrawerState}
              setThemeMode={setThemeMode}
              handleOpen={handleOpen}
              isLogInGuest={isLogInGuest}
              userInfo={userInfo}
              isDrawerOpen={isDrawerOpen}
              userProfileInfo={userProfileInfo}
              setuserInfo={setuserInfo}
              setUserLogoutAction={setUserLogoutAction}
              setuserProfileInfo={setuserProfileInfo}
              logoutLogoutAction={logoutLogoutAction}
              handleLogOut={handleLogOut}
              setUserProfileFunctions={setUserProfileFunctions}
              handleDeleteUserAccount={handleDeleteUserAccount}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              width={"70px"}
              height={"70px"}
              sx={{
                marginLeft: "15px",
              }}
            >
              <Link to={Path.HOME}>
                {themeMode === "light" ? (
                  isScroled ? (
                    <img
                      src="/assets/image/home/logo.svg"
                      alt="logo"
                      width={"100%"}
                      height={"100%"}
                    />
                  ) : location.pathname !== Path.HOME ? (
                    <img
                      src="/assets/image/home/logo.svg"
                      alt="logo"
                      width={"100%"}
                      height={"100%"}
                    />
                  ) : (
                    <img
                      src="/assets/image/home/dlogo.svg"
                      alt="logo"
                      width={"100%"}
                      height={"100%"}
                    />
                  )
                ) : (
                  <img
                    src="/assets/image/home/dlogo.svg"
                    alt="logo"
                    width={"100%"}
                    height={"100%"}
                  />
                )}
              </Link>
            </Box>

            <Box sx={{ gap: "1rem", display: { xs: "none", md: "block" } }}>
              <ul style={{ display: "flex", alignItems: "center" }}>
                {menuItems.map((item, index) => {
                  const activeTab = item?.to === currentURL;
                  return (
                    <React.Fragment key={index}>
                      <li style={listStyleLI}>
                        <NavLink
                          to={item?.to}
                          className={`nav-link ${item?.className}`}
                          style={{
                            color:
                              currentURL !== Path.HOME
                                ? themeMode === "dark"
                                  ? activeTab
                                    ? "#D4AB77"
                                    : "#fff"
                                  : activeTab
                                    ? "#1f2438"
                                    : "#1f2438"
                                : themeMode === "dark"
                                  ? activeTab
                                    ? "#D4AB77"
                                    : "#FFF"
                                  : activeTab && !isScroled
                                    ? "#D4AB77"
                                    : isScroled
                                      ? "#1f2438"
                                      : "#FFF",

                            fontWeight: activeTab ? "600" : "400",
                          }}
                        >
                          {item?.label}
                        </NavLink>
                      </li>
                    </React.Fragment>
                  );
                })}

                {propertyItems?.map((item, index) => {
                  const url = item?.to === currentURL;
                  return (
                    <React.Fragment key={index}>
                      <li key={index} style={listStyleLIForProperty}>
                        <Link
                          to={item?.to}
                          className={`nav-link ${item?.className}`}
                          style={{
                            color: url
                              ? theme.palette.primary.main
                              : theme.palette.primary.main,
                            fontWeight: url ? "800" : "400",
                            position: "relative",
                          }}
                        >
                          {item?.label}

                          <ChatBedge
                            NewChatCount={NewChatCount}
                            label={item?.label}
                          />
                        </Link>
                      </li>
                    </React.Fragment>
                  );
                })}

                <SignUpComponent
                  title={`${t("navbar.menu.SignIn")}`}
                  isLogInGuest={isLogInGuest}
                  themeMode={themeMode}
                  handleOpen={handleOpen}
                  setOpenLoginModal={setOpenLoginModal}
                  openLoginModal={openLoginModal}
                  setUserLogoutAction={setUserLogoutAction}
                  SetIsLoginGuest={SetIsLoginGuest}
                  setGoogleLoginData={setGoogleLoginData}
                  setuserProfileInfo={setuserProfileInfo}
                  setuserInfo={setuserInfo}
                  listStyleLink={listStyleLink}
                  userInfo={userInfo}
                />

                <li
                  style={{
                    ...listStyleLink,
                    position: "relative",
                    // border: "1px solid red",
                  }}
                >
                  <div
                    style={{
                      margin: "0 15px",
                      cursor: "pointer",
                    }}
                    className="langIcon"
                  >
                    <ChangeLanguageSVG
                      color={
                        location.pathname === Path.HOME &&
                          themeMode === "light" &&
                          !isScroled
                          ? "#EEE"
                          : theme.palette.primary.main
                      }
                    />
                    <div
                      className="drop-down-wrapper"
                      style={{
                        overflow: "hidden",
                        backgroundColor: "#fff",
                        color: "#1f2438",
                        position: "absolute",
                        left: "-16px",
                        borderRadius: "7px",
                        transition: "all 0.5s ease",
                        boxShadow: "2px 2px 6px 1px rgba(0, 0, 0, 0.15)",
                      }}
                    >
                      {languages.map((lang, i) => {
                        return (
                          <div key={i}>
                            <div
                              onClick={() => {
                                i18n.changeLanguage(lang.code);
                                handleLanguageChange(lang.code);
                                setLanguage && setLanguage(lang.code);
                              }}
                              className="language-item"
                              style={{
                                padding: "0.1rem 1rem",
                                cursor: "pointer",
                                margin: "0.5rem",
                                textAlign: "end",
                              }}
                              key={lang.code}
                            >
                              {lang.title}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </li>

                <li
                  style={{
                    listStyle: "none",
                    padding: "0 15px",
                    fontWeight: "400",
                  }}
                >
                  {
                    <CustomizedSwitches
                      themeMode={themeMode}
                      setThemeMode={setThemeMode}
                      isScroled={isScroled}
                      location={location.pathname}
                    />
                  }
                </li>

                <li
                  style={{
                    listStyle: "none",
                    padding: "0 15px",
                    fontWeight: "400",
                    position: "relative",
                    display: isLogInGuest ? "none" : "block",
                  }}
                >
                  <NotificationModal
                    color={
                      location.pathname === Path.HOME &&
                        themeMode === "light" &&
                        !isScroled
                        ? "#EEE"
                        : theme.palette.primary.main
                    }
                  />
                </li>

                <li
                  style={{
                    listStyle: "none",
                    padding: "0 15px",
                    display: isLogInGuest ? "none" : "block",
                  }}
                >
                  {userInfo && (
                    <UserDetailsDropDown
                      themeMode={themeMode}
                      toggleDrawer={toggleDrawer}
                      isDrawerOpen={isDrawerOpen}
                      setuserProfileInfo={setuserProfileInfo}
                      setUserLogoutAction={setUserLogoutAction}
                      logoutLogoutAction={logoutLogoutAction}
                      handleLogOut={handleLogOut}
                      handleDeleteUserAccount={handleDeleteUserAccount}
                      userInfo={userInfo}
                      setuserInfo={setuserInfo}
                    />
                  )}
                </li>
              </ul>
            </Box>

            <Box
              sx={{
                display: {
                  md: "none",
                  color: theme.palette.secondary.main,
                },
                justifyContent: "center",
              }}
            >
              <ViewHeadlineOutlinedIcon
                onClick={() => {
                  setDrawerState({ ...drawerState, left: true });
                }}
                sx={{
                  mr: "15px",
                  fontSize: "2em",
                  color: theme.palette.primary.main,
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </nav>
  );
}
