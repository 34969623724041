export const Navabar_EN_json = {
  menu: {
    home: "Home",
    about: "About us",
    contact: "Contact us",
    help: "Help",
    property: "Add Property",
    chat: "Chat",
    propertyS: "Property",
    login: "Login",
    SignIn: "Sign in",
    profile: "Profile",
    userProfile: {
      edit: {
        title: "Edit Profile",
        button: "Update",
        add: "Address",
        fname: "firstname",
        lname: "lastname",
        email: "Email",
        phone: "Phone Number"
      },
      history: "Property History",
      hScreen: {
        title: "Property History",
        all: "All",
        sell: "Sell",//buy
        rent: "Rent"
      },
      myWishlist: "My WishList",
      wishlist: "My Wishlist",
      req2yProp: "Request to your Properties",
      logout: {
        mainTitle: "Log Out",
        title: "Logout",
        msg: "Are you sure you want to logout ?",
        cancle: "Cancel",

      },
      delete: {
        mainTitle: "Delete Account",
        title: "Delete Account",
        msg: "Are you sure you want to delete your account ?",
        cancle: "Cancel",
        delete: "Delete",

      },
    }
  },
};
// export const Navabar_AR_json = {
//   menu: {
//     home: "بيت",
//     about: "معلومات عنا",//n
//     contact: "اتصل بنا",
//     help: "يساعد",
//     property: "إضافة عقار",//n
//     chat: "محادثة",//n
//     propertyS: "ملكية",
//     login: "تسجيل الدخول",//n
//     SignIn: "التسجيل",//n
//     profile: "الملف الشخصي",//n
//     userProfile: {
//       edit: {
//         title: "تعديل الملف الشخصي",
//         button: "تحديث",//n
//         add: "العنوان*",//n
//         fname: "الاسم الأول*",//n
//         lname: "الاسم الأخير*",//n
//         email: "البريد الالكتروني*",///n
//         phone: "رقم الهاتف*"//n
//       },
//       history: "تاريخ العقار",//n
//       hScreen: {
//         title: "تاريخ العقار",//n
//         all: "الكل",//n
//         sell: "يشتري",//buy
//         rent: "إيجار"//n
//       },

//       reqPro: "طلب خصائص",
//       requested: "العقارات المطلوبة",//n
//       req2yProp: "الطلبات على عقارك",//n
//       req2yPropHistory: "طلب إلى تاريخ الممتلكات الخاصة بك",
//       logout: {
//         mainTitle: "تسجيل الخروج",//n
//         title: "تسجيل الخروج",//n
//         msg: "هل أنت متأكد من أنك تريد تسجيل الخروج؟",//n
//         cancle: "إلغاء",//n
//       },
//       delete: {
//         mainTitle: "إلغاء الحساب",//n
//         title: "إلغاء الحساب",//n
//         msg: "؟\naccount هل أنت متأكد من رغبتك بإلغاء حسابك ",//n
//         cancle: "إلغاء",//n
//         delete: "يمسح",//n
//       },
//     }
//   },
// };
export const Navabar_AR_json = {
  menu: {
    home: "الرئيسية",
    about: "معلومات عنا",
    contact: "اتصل بنا",
    help: "المساعدة",
    property: "إضافة عقار",
    chat: "محادثة",
    propertyS: "العقار",
    login: "تسجيل الدخول",
    SignIn: "التسجيل",
    profile: "الملف الشخصي",
    userProfile: {
      edit: {
        title: "تعديل الملف الشخصي",
        button: "تحديث",
        add: "العنوان",
        fname: "الاسم الأول",
        lname: "الاسم الأخير",
        email: "البريد الإلكتروني",
        phone: "رقم الهاتف"
      },
      history: "تاريخ العقار",
      hScreen: {
        title: "تاريخ العقار",
        all: "الكل",
        sell: "يبيع",
        rent: "إيجار"
      },
      myWishlist: "قائمة أمنياتي",
      requested: "تاريخ العقارات المطلوبة",
      req2yProp: "طلبات على عقاراتك",
      logout: {
        mainTitle: "تسجيل الخروج",
        title: "تسجيل الخروج",
        msg: "هل أنت متأكد من أنك تريد تسجيل الخروج؟",
        cancle: "إلغاء",
      },
      delete: {
        mainTitle: "إلغاء الحساب",
        title: "إلغاء الحساب",
        msg: "هل أنت متأكد من رغبتك في إلغاء حسابك؟",
        cancle: "إلغاء",
        delete: "حذف",
      },
    }
  },
};
