import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import Grid from "@mui/material/Grid";
import { CircularProgress, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import upload from "../../common/assets/image/upload.webp"; // Optional: For visual reference, like in the media modal

export default function VideoUploadModal({
  data,
  handleVideoChange,
  removeImage,
}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = React.useState(false);
  const theme = useTheme();
  const { t } = useTranslation();
  const fileInputRef = React.useRef(null);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "95%", sm: "85%", md: "60%", lg: "60%" },
    boxShadow: 24,
    borderRadius: "12px",
    p: 4,
    minHeight: `60%`,
    maxHeight: `calc(100vh - 100px)`,
    overflow: "auto",
    boxSizing: "border-box",
  };

  const handleFileChange = (e) => {
    const fileInput = fileInputRef.current;
    handleVideoChange(e, "video");

    if (fileInput) {
      fileInput.value = null;
    }
  };

  // Function to remove the video by index
  const removeVideo = (index) => {
    removeImage(index); // Call the removeImage function passed via props
  };

  return (
    <div>
      <Button
        component="span"
        sx={{
          textTransform: "capitalize",
          bgcolor: "#1f2438",
          color: "white ",
          borderRadius: "7px",
          padding: "0.01em 1em",
          "&:hover": {
            backgroundColor: "#1f2438",
          },
        }}
        onClick={handleOpen}
      >
        {t("addProperty.video.button")}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          "& .MuiModal-backdrop": {
            backdropFilter: "blur(4px)",
          },
        }}
      >
        <Box
          sx={{
            ...style,
            bgcolor: theme.palette.navbarBG.main,
            color: theme.palette.dark.main,
          }}
        >
          {loading ? (
            <Box
              sx={{
                height: "100%",
                boxSizing: "border-box",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
              }}
            >
              <CircularProgress disableShrink />
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  pb: "1rem",
                }}
              >
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    padding: "0.3em 1em",
                    textTransform: "capitalize",
                    bgcolor: theme.palette.gold.main,
                    borderRadius: "12px",
                    fontSize: "1.1rem",
                    color: "white",
                  }}
                  onClick={handleClose}
                >
                  {t("addProperty.video.close")}
                </Button>
              </Box>

              {/* Video Upload Area with DropZone-like Layout */}
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <label htmlFor={`fileInput`}>
                    <Box
                      sx={{
                        border: "1px dashed #80808054",
                        width: "100%",
                        height: "200px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "12px",
                        cursor: "pointer",
                        position: "relative",
                      }}
                    >
                      <Box
                        sx={{
                          width: "50%",
                          height: "100%",
                          display: { xs: "none", sm: "flex" },
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          sx={{
                            display: { xs: "none", sm: "flex" },
                            justifyContent: "center",
                            height: "100%",
                            width: "50%",
                            position: "relative",
                          }}
                        >
                          <img
                            src={upload}
                            style={{
                              height: "100%",
                              width: "100%",
                            }}
                            alt=""
                          />
                        </Box>
                      </Box>

                      <AddOutlinedIcon
                        sx={{ fontSize: "3rem", fill: "#80808054" }}
                      />
                      <Box sx={{ width: "50%" }}>
                        <Box
                          sx={{
                            fontWeight: "bold",
                            fontSize: "1.2rem",
                            textAlign: "center",
                          }}
                        >
                          Drag or Select file
                        </Box>
                        <Box sx={{ opacity: "0.7", textAlign: "center" }}>
                          Drop files here or click here...
                        </Box>
                      </Box>
                    </Box>
                    <input
                      hidden
                      type="file"
                      id={`fileInput`}
                      accept={`video/*`}
                      onChange={handleFileChange}
                      ref={fileInputRef} // Set the ref
                      multiple // Allow selecting multiple files
                    />
                  </label>
                </Grid>

                {/* Display Video Previews */}
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  {data &&
                    data.map((item, index) => (
                      <Grid item xs={6} sm={4} md={3.4} xl={2.5} key={index}>
                        <Box
                          sx={{
                            position: "relative",
                            border: "1px solid #80808054",
                            width: { xs: "100%", sm: "150px" },
                            height: { xs: "150px", sm: "150px" },
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "12px",
                            minWidth: "120px",
                          }}
                        >
                          {/* Video Preview */}
                          <video
                            width="100%"
                            height="100%"
                            controls
                            src={URL.createObjectURL(item.video)} // Using video file
                            style={{
                              borderRadius: "12px",
                              objectFit: "cover",
                            }}
                          />

                          {/* Cross Button to Remove Video */}
                          <IconButton
                            sx={{
                              position: "absolute",
                              top: "5px",
                              right: "5px",
                              backgroundColor: "rgba(255, 255, 255, 0.7)",
                              color: "red",
                              borderRadius: "50%",
                              boxShadow: "0px 2px 5px rgba(0,0,0,0.2)",
                              "&:hover": {
                                backgroundColor: "rgba(255, 0, 0, 0.2)",
                              },
                            }}
                            onClick={() => removeVideo(index)} // Call removeVideo with index
                          >
                            <ClearIcon />
                          </IconButton>
                        </Box>
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
}
