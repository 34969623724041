import React, { useEffect, useState } from "react";
import PropertyCard from "../PropertyCard";
import apiHelper from "../../../common/API/ApiHelper";
import { NoData } from "../../../common/Svg";
import Loader from "../../../common/Loader";
import { useLocation } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { Box, CircularProgress, Grid } from "@mui/material";

export default function AllPropertyScreen() {
  const [fetchProperty, SetFetchedProperty] = useState([]);
  const location = useLocation();
  let dataLength = 0;
  const [loading, setLoading] = useState(true);
  const [moreData, setMoreData] = useState(false);
  const { ref, inView } = useInView();

  const getPropertyList = async (count = 0) => {
    try {
      // setLoading(true);
      const result = await apiHelper.getPropertyList(count);
      if (result?.data?.r.length > 0 && result.status === 200) {
        setMoreData(true);
        if (count !== 0) {
          SetFetchedProperty((prev) =>
            prev.length > 0 ? [...prev, ...result?.data?.r] : result?.data?.r
          );
        } else {
          SetFetchedProperty(result.data.r);
        }
        setLoading(false);
      } else {
        setLoading(false);
        setMoreData(false);
      }
    } catch (error) {
      error?.response?.data?.message
      ? console.log(error?.response?.data?.message)
      : console.log(error.message);
    }
  };

  useEffect(() => {
    window.scrollTo({ behavior: "instant", top: "0" });
  }, []);

  useEffect(() => {
    if (fetchProperty.length && inView && moreData) {
      //eslint-disable-next-line
      dataLength = fetchProperty?.length;
      getPropertyList(dataLength);
    }
  }, [inView]);

  useEffect(() => {
    getPropertyList();
    //eslint-disable-next-line
  }, [location.pathname]);

  return (
    <>
      {loading && fetchProperty.length === 0 ? (
        <Box
          sx={{
            minHeight: `calc( 100vh - 172px)`,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader />
        </Box>
      ) : fetchProperty.length === 0 && !loading ? (
        <Box
          sx={{
            minHeight: `calc( 100vh - 172px)`,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // border:"solid red"
          }}
        >
          <NoData />
        </Box>
      ) : (
        <Box sx={{ minHeight: `calc( 100vh - 154px )`, pb: "1rem" }}>
          <Grid container spacing={"1.5rem"}>
            {fetchProperty &&
              fetchProperty?.map((property, index) => (
                <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={3}>
                  <PropertyCard x={property} />
                </Grid>
              ))}
          </Grid>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              color: "grey",
              mt: "1rem",
            }}
            ref={ref}
          >
            {loading ? (
              <CircularProgress color="error" />
            ) : (
              !moreData && "No more data."
            )}
          </Box>
        </Box>
      )}
    </>
  );
}
