import React, { useEffect, useState } from "react";
import apiHelper from "../../common/API/ApiHelper";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import Path from "../../common/Path";
import { useNavigate } from "react-router-dom";
import PropCard from "./PropCard";
import { useTranslation } from "react-i18next";

function RentPropList() {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setloading] = useState(true);

  const fetchData = async (count = 0) => {
    try
    {
      const res = await apiHelper.get_RENT_PropertyList(count);
      setData(res?.data?.r);
      setloading(false);
    } catch (error)
    {
      setloading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box
      sx={{
        boxSizing: "border-box",
        padding: { xs: "1rem", md: "1rem 2rem 2rem 2rem" },
        // border: "solid red",
        display: "flex",
        justifyContent: "center",
        overflow: "hidden",
      }}
    >
      <Box sx={{ width: { xs: "100%", lg: "70%" } }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "1rem 0",
            //   border: "solid red",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: { xs: "20px", md: "22px" },
              color: "#1f2438",
            }}
          >
            {t("landingPage.list.rentTitle")}
          </Typography>
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: { xs: "16px" },
              cursor: "pointer",
              background: theme.palette.gold.main,
              borderRadius: "12px",
              color: "white",
              padding: "0.5rem 1rem ",
              alignContent: "flex-end",
            }}
            onClick={() => navigate(`${Path.PROPERTY}?value=1`)}
          >
            {t("landingPage.list.more")}{" "}
          </Typography>
        </Box>
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {loading ? (
            <Box
              sx={{
                height: "350px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress color="grey" />
            </Box>
          ) : data && data?.length > 0 ? (
            data?.map((x, i) => {
              return (
                i < 3 && <Grid key={i} item xs={12} sm={6} md={4}>
                  <PropCard x={x} />
                </Grid>
              );
            })
          ) : (!loading &&
            <Box
              sx={{
                height: "350px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "grey",
              }}
            >
              No data found
            </Box>
          )}
        </Grid>
      </Box>
    </Box>
  );
}

export default RentPropList;
