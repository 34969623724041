import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Avatar,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { MsgSendSVG, TextSVG } from "../../common/Svg";
import apiHelper from "../../common/API/ApiHelper";
import { useLocation } from "react-router-dom";
import { baseUrl } from "../../common/Constant";
import moment from "moment";
import { useInView } from "react-intersection-observer";
import { LanguageContext } from "../../Context/LanguageContext";
import BlockIcon from "@mui/icons-material/Block";
import "./chatStyle.css";
import MsgDeleteModal from "./delete/MsgDeleteModal";
import { socket } from "../../App";
import defaultImg from "../../common/assets/image/User.svg";

let dataLength = 0;

const ChattingUserMsgHandler = (props) => {
  const { ChatFunction, setNewChatCount } = useContext(LanguageContext);
  const { themeMode } = props;
  const theme = useTheme();
  const location = useLocation();
  const query = location.search;
  const params = new URLSearchParams(query);
  const chatId = params.get("chatId");
  const uId = params.get("uId");
  const role = params.get("role");
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [loading, setloading] = useState(false);
  const [moreData, setMoreData] = useState(false);
  const { ref, inView } = useInView();
  const [DeletedMsgData, setDeletedMsgData] = useState();
  const [isImageError, setIsImageError] = useState(false);

  let userInfo;
  try {
    userInfo = JSON.parse(localStorage.getItem("userInfo"));
  } catch (error) {
    userInfo = null;
  }

  const getChatMessages = async (count = 0) => {
    try {
      setloading(true);
      const res = await apiHelper.GetUserChatMsg(Number(chatId), count);
      if (res?.data?.r.length > 0 && res.status === 200) {
        setMoreData(true);
        setMessages((prev) =>
          count !== 0 ? [...res?.data?.r, ...prev] : res?.data?.r
        );
      } else {
        setMoreData(false);
      }
      setTimeout(() => {
        setloading(false);
      }, 355);
    } catch (error) {
      console.error("Error fetching chat messages:", error);
      error?.response?.data?.message
        ? console.log(error?.response?.data?.message)
        : console.log(error.message);
    }
  };

  const checkForNewChat = async () => {
    try {
      const res = await apiHelper.GetChatList();
      const hasUnreadChats = res.data.r.some(
        (chat) => chat.unread_msg_count > 0
      );
      hasUnreadChats ? setNewChatCount(true) : setNewChatCount(false);
    } catch (error) {
      console.log(error);
    }
  };

  const UnreadMsgCountHandler = async (ChatID, msgID) => {
    try {
      //eslint-disable-next-line
      const res = await apiHelper.UnreadCountHandler(ChatID, msgID);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getChatMessages(); //eslint-disable-next-line
    checkForNewChat();
  }, [chatId]);

  const handleSendMessage = async () => {
    try {
      if (!loading) {
        if (newMessage.trim() === "") return;

        const message = {
          msg: newMessage,
          chat_id: Number(chatId),
          reciever_id: Number(uId),
          sender_id: userInfo.id,
          type: 1,
        };

        setloading(true);
        const res = await apiHelper.SendMsg(message);
        setMessages((preMsg) => [res?.data?.r, ...preMsg]);
        socket && socket?.emit("send-msg", res?.data?.r);
        setNewMessage("");
        setloading(false);
        UnreadMsgCountHandler(Number(chatId), Number(res?.data?.r?.id));
        ChatFunction && ChatFunction.GetUserChatList();
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  // Handling Get-Msg
  useEffect(() => {
    const handleMessage = (msg) => {
      if (Number(msg.chat_id) === Number(chatId)) {
        if (msg.is_delete === 1) {
          setMessages((prevMessages) =>
            prevMessages.map((item, i) => {
              if (item?.id === msg?.id) {
                return { ...item, is_delete: 1 };
              } else {
                return item;
              }
            })
          );
          UnreadMsgCountHandler(Number(msg.chat_id), Number(msg.id));
        } else {
          UnreadMsgCountHandler(Number(msg.chat_id), Number(msg.id));
          setMessages((prevMessages) => [msg, ...prevMessages]);
        }
      }
      ChatFunction && ChatFunction.GetUserChatList();
      checkForNewChat();
    };

    socket && socket?.on("get-msg", handleMessage);

    return () => {
      socket && socket?.off("get-msg", handleMessage);
    }; //eslint-disable-next-line
  }, [socket, chatId]);

  useEffect(() => {
    if (messages.length > 28 && inView && moreData) {
      //eslint-disable-next-line
      dataLength = messages?.length;
      getChatMessages(dataLength);
    } //eslint-disable-next-line
  }, [inView]);

  const byDate = messages.reduce((obj, item) => {
    const messageDate = moment(item.updated_at);
    let displayDate;

    if (messageDate.isSame(moment(), "day")) {
      displayDate = "Today";
    } else if (messageDate.isSame(moment().subtract(1, "days"), "day")) {
      displayDate = "Yesterday";
    } else {
      displayDate = messageDate.format("DD/MM/YY");
    }

    if (obj[displayDate]) {
      obj[displayDate].push(item);
    } else {
      obj[displayDate] = [{ ...item }];
    }

    return obj;
  }, {});

  // Handling local msg delete
  useEffect(() => {
    setMessages((messages) =>
      messages.map((message, i) => {
        if (message?.id === DeletedMsgData?.id) {
          return {
            ...message,
            is_delete: DeletedMsgData?.is_delete ? 1 : 0,
            is_i_delete: DeletedMsgData?.is_delete ? 0 : 1,
          };
        } else {
          return message;
        }
      })
    );
    ChatFunction && ChatFunction.GetUserChatList(); // eslint-disable-next-line
  }, [DeletedMsgData, setMessages]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
        height: `calc(100vh - 210px)`,
      }}
      className={"HideScrollBar"}
    >
      <Box
        sx={{
          flex: 1,
          overflow: "scroll",
          display: "flex",
          flexDirection: "column-reverse",
          height: `calc(100% - 55px)`,
        }}
      >
        {Object.entries(byDate).map(([date, msges], i) => (
          <>
            {msges?.map((message, i) => (
              <>
                {
                  <>
                    {/* Display msg */}
                    {!(message?.is_i_delete > 0) &&
                      message?.is_delete === 0 && (
                        <Box
                          key={i}
                          className="msgContainer"
                          sx={{
                            m: "1px 0px",
                            alignSelf:
                              message?.sender_id === userInfo?.id
                                ? "end"
                                : "start",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              position: "relative",
                              flexDirection:
                                message?.sender_id === userInfo?.id
                                  ? "row"
                                  : "row-reverse",
                              textAlign:
                                message?.sender_id === userInfo?.id
                                  ? "end"
                                  : "start",
                            }}
                          >
                            <Typography
                              sx={{
                                margin: "0",
                                fontFamily: "Roboto",
                                fontWeight: "400",
                                textAlign: "left",
                                color:
                                  theme.palette.mode === "light"
                                    ? message?.sender_id !== userInfo?.id
                                      ? "#EEE"
                                      : "#1f2438"
                                    : message?.sender_id !== userInfo?.id
                                    ? "#EEE"
                                    : "#1f2438",
                                borderRadius:
                                  message?.sender_id === userInfo?.id
                                    ? "15px 15px 0px 15px"
                                    : "15px 15px 15px 0",
                                backgroundColor:
                                  theme.palette.mode === "light"
                                    ? message?.sender_id === userInfo?.id
                                      ? "#EEE"
                                      : "#1f2438"
                                    : message?.sender_id !== userInfo?.id
                                    ? "#181C2F"
                                    : "#EEE",
                                padding: "6px 12px",
                                fontSize: "16px",
                                letterSpacing: "0.36px",
                                boxShadow:
                                  "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;",
                                maxWidth: "300px",
                                width: "fit-content",
                                wordBreak: "break-all",
                                marginBottom: "5px",
                              }}
                              component="div"
                            >
                              {message?.msg}
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent:
                                    message?.sender_id === userInfo?.id
                                      ? "end"
                                      : "start",
                                  fontSize: "0.7rem",
                                  color:
                                    theme.palette.mode === "light"
                                      ? message?.sender_id !== userInfo?.id
                                        ? "#EEE70"
                                        : "#1f2438"
                                      : message?.sender_id !== userInfo?.id
                                      ? "#EEE"
                                      : "#1f2438",
                                }}
                              >
                                <span>
                                  {moment(message?.updated_at).format("hh:mm A")}
                                </span>
                              </Box>
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                gap: 1,
                              }}
                            >
                              <div className="chat__options">
                                <MsgDeleteModal
                                  me={
                                    message?.sender_id === userInfo?.id
                                      ? true
                                      : false
                                  }
                                  msgID={message?.id}
                                  setDeletedMsgData={setDeletedMsgData}
                                />
                              </div>
                              {isImageError ? (
                                <Box
                                  width="100%"
                                  height={"100%"}
                                  borderRadius={"50%"}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: "2rem",
                                    fontWeight: "600",
                                    opacity: "0.7",
                                  }}
                                >
                                  {message?.sender}
                                </Box>
                              ) : (
                                <Avatar
                                  alt="User"
                                  sx={{
                                    width: 18,
                                    height: 18,
                                  }}
                                  onError={() => setIsImageError()}
                                  src={
                                    role === "2"
                                      ? message?.sender?.agency_logo === null
                                        ? defaultImg
                                        : message?.sender?.agency_logo && `${baseUrl}/uploads/agency/logo/${message?.sender?.agency_logo}`
                                      : message?.sender?.profile === null
                                      ? message?.sender?.agency_logo === null
                                        ? defaultImg
                                          : message?.sender?.agency_logo && `${baseUrl}/uploads/agency/logo/${message?.sender?.agency_logo}`
                                        : message?.sender?.profile && `${baseUrl}/uploads/user/profile/${message?.sender?.profile}`
                                  }
                                />
                              )}
                            </Box>
                          </Box>
                        </Box>
                      )}

                    {/* display delete msg */}
                    {message?.is_i_delete === 0 && message.is_delete > 0 && (
                      <Box
                        key={message.id}
                        className="msgContainer"
                        sx={{
                          m: "1px 0px",
                          marginBottom: "5px",
                          alignSelf:
                            message?.sender_id === userInfo?.id
                              ? "end"
                              : "start",
                        }}
                      >
                        <Box
                          sx={{
                            p: 1,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "0.1rem",
                            boxShadow:
                              "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;",
                            borderRadius: "12px",
                            boxSizing: "border-box",
                            color: "gray",
                            m: "0 1.5rem",
                          }}
                        >
                          <BlockIcon />
                          {message?.sender_id === userInfo?.id
                            ? "You deleted this message."
                            : "This message was deleted."}
                        </Box>
                      </Box>
                    )}
                  </>
                }
              </>
            ))}
            <Box
              sx={{
                width: "100%",
                // border: "solid red",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "sticky",
                bottom: "0",
              }}
            >
            
              <Box
                sx={{
                  padding: "0.5rem 2rem",
                  borderRadius: "12px",
                  color: theme.palette.dark.main,
                  fontWeight: "600",
                  letterSpacing: "1px",
                  opacity: "0.7",
                  // display: msges.length === 1 && msges[0].is_i_delete > 0 ? 'block' : 'none',
                }}
              >
                {date}
              </Box>
             
            </Box>
          </>
        ))}

        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            opacity: 0,
          }}
          ref={ref}
        >
          {loading && <CircularProgress color="error" />}
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          maxHeight: "40px",
          width: "100%",
          padding: "0px 0px 12px 0px",
          position: "relative",
          zIndex: 0,
        }}
      >
        <TextField
          variant="outlined"
          fullWidth
          placeholder="Type your message here. . ."
          value={newMessage}
          autoComplete="off"
          onChange={(e) => setNewMessage(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <TextSVG color={theme.palette.dark.main} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position="end"
                onClick={handleSendMessage}
                sx={{ cursor: "pointer" }}
              >
                <MsgSendSVG color={theme.palette.dark.main} />
              </InputAdornment>
            ),
          }}
          sx={{
            "& input": {
              color: `${themeMode === "light" ? "#1f2438" : "#eee"} !important`,
              padding: "11px",
            },
            "& fieldset": {
              borderRadius: "12px",
            },
          }}
          onKeyPress={(e) => {
            if (e.charCode === 13) {
              e.preventDefault();
              handleSendMessage();
            }
          }}
        />
      </Box>
    </Box>
  );
};

export default ChattingUserMsgHandler;
