import { Route, Routes } from "react-router-dom";
import Path from "./Path";
import HomeScreen from "../screen/Home/HomeScreen";
import SignInScreen from "../screen/SignIn/SignInScreen";
import AddPropertyScreen from "../screen/AddProperty/AddPropertyScreen";
import PropertyDetailScreen from "../screen/Property/PropertyDetails/PropertyDetailScreen";
import PropertyHistoryScreen from "../screen/Property/History/PropertyHistoryScreen";
import AllPropertyHistoryScreen from "../screen/Property/History/All/AllPropertyHistoryScreen";
import AboutScreen from "../screen/About/AboutScreen";
import ContactScreen from "../screen/Contact/ContactScreen";
import HelpScreen from "../screen/Help/HelpScreen";
import PropertyEvaluationScreen from "../screen/PropertyEvaluation/PropertyEvaluation";
import ResidentEvaluation from "../screen/PropertyEvaluation/Resident/ResidentEvaluation";
import ResidentCalculation from "../screen/PropertyEvaluation/Resident/ResidentCalculation";
import CommercialEvaluation from "../screen/PropertyEvaluation/Commercial/CommercialEvaluation";
import CommercialCalculation from "../screen/PropertyEvaluation/Commercial/CommercialCalculation";
import IndustrialEvaluation from "../screen/PropertyEvaluation/Indusrial/IndustrialEvaluation";
import IndustrialCalculation from "../screen/PropertyEvaluation/Indusrial/IndustrialCalculation";
import AgriculturalEvaluation from "../screen/PropertyEvaluation/Agricultural/AgriculturalEvaluation";
import AgriculturalCalculation from "../screen/PropertyEvaluation/Agricultural/AgriculturalCalculation";
import AllPropertyScreen from "../screen/Property/All/AllPropertyScreen";
import SearchedPropertyScreen from "../screen/Search/SearchedPropertyScreen";
import RequestedPropertyScreen from "../screen/Property/User/RequestedProperty";
import GetRequestToYourPropertyScreen from "../screen/Property/User/GetPropertyRequest";
import ProfileScreen from "../screen/SignIn/ProfileScreen";
import ChatByIdScreen from "../screen/Chat/ChatByIdScreen";
import NotificationScreen from "../screen/Notification/NotificationScreen";
import AgencyNotFound from "../screen/SignIn/user/AgencyNotFound.jsx";
import PendigScreen from "../screen/SignIn/user/PendigScreen.jsx";
import Middleware from "./Middleware.jsx";
import RejectScreen from "../screen/SignIn/user/RejectScreen.jsx";
import AccountDeleted from "../screen/SignIn/user/AccountDelete.jsx";
import PropertyScreenCopy from "../screen/Property/PropertyScreen copy.jsx";
import { lazy, Suspense } from "react";
import Loader from "./Loader.jsx";
import { useTheme } from "@mui/system";
import UpdatedPropertyDetailScreen from "../screen/Property/PropertyDetails/UpdatedPropertyDetailScreen.jsx";
const ChatScreen = lazy(() => import("../screen/Chat/ChatScreen.jsx"));

export default function RouteFile(props) {
  const {
    setuserInfo,
    handleOpen,
    userInfo,
    userProfileFunctions,
    themeMode,
    verifyUserLogin,
    googleLoginData,
    SetIsLoginGuest,
    isLogInGuest,
  } = props;
  const theme = useTheme();
  return (
    <Routes>
      <Route
        path={Path.HOME}
        element={<Middleware children={<HomeScreen themeMode={themeMode} />} />}
      />
      <Route
        path={Path.ABOUT}
        element={<Middleware children={<AboutScreen />} />}
      />
      <Route
        path={Path.AGENCY_NOT_FOUND}
        element={
          <AgencyNotFound handleOpen={handleOpen} themeMode={themeMode} />
        }
      />
      <Route
        path={Path.DELETE}
        element={
          <AccountDeleted handleOpen={handleOpen} themeMode={themeMode} />
        }
      />
      <Route
        path={Path.REJECT}
        element={<RejectScreen handleOpen={handleOpen} themeMode={themeMode} />}
      />
      <Route
        path={Path.APPROVAL}
        element={<PendigScreen handleOpen={handleOpen} themeMode={themeMode} />}
      />
      <Route
        path={Path.CONTACT}
        element={<ContactScreen verifyUserLogin={verifyUserLogin} />}
      />
      <Route
        path={Path.HELP}
        element={
          <Middleware
            children={<HelpScreen verifyUserLogin={verifyUserLogin} />}
          />
        }
      />
      <Route
        path={Path.NOTIFICATION}
        element={
          <Middleware
            children={<NotificationScreen verifyUserLogin={verifyUserLogin} />}
          />
        }
      />
      <Route
        path={Path.SIGN_IN}
        element={
          <SignInScreen
            setuserInfo={setuserInfo}
            SetIsLoginGuest={SetIsLoginGuest}
            isLogInGuest={isLogInGuest}
            googleLoginData={googleLoginData}
          />
        }
      />

      <Route
        path={Path.PROPERTY}
        // element={<Middleware children={<PropertyScreen themeMode={themeMode} />} />}
        element={
          <Middleware children={<PropertyScreenCopy themeMode={themeMode} />} />
        }
      >
        <Route index element={<AllPropertyScreen />} />
      </Route>

      <Route
        path={Path.PROPERTY_HISTORY}
        element={
          <Middleware
            children={<PropertyHistoryScreen themeMode={themeMode} />}
          />
        }
      >
        <Route index element={<AllPropertyHistoryScreen />} />
      </Route>

      <Route
        path={Path.ADD_PROPERTY}
        element={
          <Middleware
            children={<AddPropertyScreen verifyUserLogin={verifyUserLogin} />}
          />
        }
      />

      <Route
        path={Path.CHAT}
        element={
          <Middleware
            children={
              <Suspense
                fallback={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      minHeight: "100vh",
                      width: "100%",
                      // bgcolor: theme.palette.navbarBG.main,
                      color: theme.palette.dark.main,
                      backgroundColor: theme.palette.navbarBG.main,
                    }}
                  >
                    <Loader />
                  </div>
                }
              >
                <ChatScreen
                  index
                  themeMode={themeMode}
                  verifyUserLogin={verifyUserLogin}
                />
              </Suspense>
            }
          />
        }
      >
        <Route path={`${Path.CHATBYID}`} element={<ChatByIdScreen />} />
      </Route>

      <Route
        path={Path.USER_PROFILE}
        element={
          <Middleware
            children={
              <ProfileScreen
                userInfoUpdated={userInfo}
                userProfileFunctions={userProfileFunctions}
                themeMode={themeMode}
                verifyUserLogin={verifyUserLogin}
              />
            }
          />
        }
      />

      <Route
        path={Path.REQUESTED_PROPERTY}
        element={
          <RequestedPropertyScreen
            themeMode={themeMode}
            verifyUserLogin={verifyUserLogin}
          />
        }
      />

      <Route
        path={Path.REQUESTE_TO_USERS_PROPERTY}
        element={
          <GetRequestToYourPropertyScreen
            themeMode={themeMode}
            verifyUserLogin={verifyUserLogin}
          />
        }
      />

      <Route
        path={Path.PROPERTY_EVALUATION}
        element={
          <Middleware
            children={<PropertyEvaluationScreen themeMode={themeMode} />}
          />
        }
      >
        <Route index element={<ResidentEvaluation />} />
        <Route
          path={Path.PROPERTY_EVALUATION_RESIDENCE_CALC}
          element={<ResidentCalculation />}
        />
        <Route
          path={Path.PROPERTY_EVALUATION_COMMERCIAL}
          element={<CommercialEvaluation />}
        />
        <Route
          path={Path.PROPERTY_EVALUATION_COMMERCIAL__CALC}
          element={<CommercialCalculation />}
        />
        <Route
          path={Path.PROPERTY_EVALUATION_INDUSTRIAL}
          element={<IndustrialEvaluation />}
        />
        <Route
          path={Path.PROPERTY_EVALUATION_INDUSTRIAL__CALC}
          element={<IndustrialCalculation />}
        />
        <Route
          path={Path.PROPERTY_EVALUATION_AGRICULTURAL}
          element={<AgriculturalEvaluation />}
        />
        <Route
          path={Path.PROPERTY_EVALUATION_AGRICULTURAL__CALC}
          element={<AgriculturalCalculation />}
        />
      </Route>

      {/* <Route
        path={`${Path.PROPERTY_DETAILS}/:id`}
        element={
          <PropertyDetailScreen
            themeMode={themeMode}
            verifyUserLogin={verifyUserLogin}
          />
        }
      /> */}
      <Route
        path={`${Path.PROPERTY_DETAILS}/:id`}
        element={
          <UpdatedPropertyDetailScreen
            themeMode={themeMode}
            verifyUserLogin={verifyUserLogin}
          />
        }
      />

      <Route
        path={`${Path.SEARCH_PROPERTY}/:cat/:lat/:lng/:type`}
        // path={`${Path.SEARCH_PROPERTY}`}
        element={<SearchedPropertyScreen verifyUserLogin={verifyUserLogin} />}
      />
    </Routes>
  );
}
