import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import { Fragment, useContext, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Path from "../../common/Path";
import formValidator from "../../common/Validator";
import InputComponent from "../../component/Input/InputComponentTextField";
import InputComponentOutline from "../../component/Input/InputComponentOutline";
import { toast } from "react-toastify";
import apiHelper from "../../common/API/ApiHelper";
import ForgotPasswordDialogue from "./ForgotPassword";
import { signInWithPopup } from "firebase/auth";
import { auth, getFirebaseToken, provider } from "../../common/Firebase";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../Context/LanguageContext";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { AppleLogo } from "../../common/Svg";
import "./style.css";

export default function CustomerLoginDialogue(props) {
  const {
    displayName,
    themeMode,
    setGoogleLoginData,
    handleOpen,
    setOpenLoginModal,
    openLoginModal,
    SetIsLoginGuest,
    setuserProfileInfo,
    setuserInfo,
  } = props;
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { language } = useContext(LanguageContext);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [validationError, setValidationError] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [loginFormDetails, setloginFormDetails] = useState({
    email: "",
    password: "",
  });

  // input function and state
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleFormDetails = (e) => {
    const { name, value } = e.target;
    const updatedFormDetails = { ...loginFormDetails, [name]: value };
    setloginFormDetails(updatedFormDetails);

    if (isSubmitted)
    {
      const validationResult = formValidator(
        updatedFormDetails,
        "login",
        "",
        t
      );
      setValidationError(validationResult);
    }
  };

  const removeLocalData = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userInfo");
    localStorage.removeItem("deviceID");
  };

  const handleFormSubmission = async () => {
    try
    {
      setIsSubmitted(true);
      const validationResult = formValidator(loginFormDetails, "login", "", t);
      if (validationResult.length > 0)
      {
        setValidationError(validationResult);
        return;
      }
      setIsLoading(true);
      setValidationError([]);
      const result = await apiHelper.login(loginFormDetails);
      const status = result?.data?.r?.status;
      const role = result?.data?.r?.role;
      const agencyAvailable = result?.data?.r?.agency?.length > 0;

      if (Number(status) === 2 && (role === 1 || role === 2))
      {
        removeLocalData();
        setIsLoading(false);
        navigate(Path.APPROVAL);
        handleClose();
      } else if (Number(status) === -1 && (role === 1 || role === 2))
      {
        navigate(Path.REJECT);
        setIsLoading(false);
        handleClose();
      } else if (Number(status) === 0)
      {
        toast.error(
          language === "ar"
            ? "لم تتم المصادقة عليك."
            : "You are not authenticated."
        );
        removeLocalData();
        setIsLoading(false);
        handleClose();
      } else if (result?.data?.m === "Success" && Number(status) === 1)
      {
        const userInfo = result?.data?.r;
        const token = result?.data?.r?.token;
        localStorage.setItem("token", token);
        localStorage.setItem("userInfo", JSON?.stringify(userInfo));
        const deviceId = await generateDeviceId(
          result?.data?.r?.id,
          result?.data?.r?.created_at
        );
        localStorage.setItem("deviceID", deviceId);
        await getFirebaseToken("add");
        // await apiHelper.SET_FCM_token({ token: token, device_id: deviceId });
        SetIsLoginGuest(false);
        setuserInfo(userInfo);
        setuserProfileInfo(userInfo);
        toast.success(
          language === "en" ? "Login Successfully" : "تم تسجيل الدخول بنجاح"
        );
        setIsLoading(false);
        handleClose();
        location.pathname.includes("/property/details") && navigate(-1);
        if (
          location.pathname === Path.AGENCY_NOT_FOUND ||
          location.pathname === Path.APPROVAL
        )
        {
          navigate(Path.PROPERTY);
        } else if (location.pathname === Path.SIGN_IN)
        {
          navigate(Path.HOME);
        }
      } else if (result?.data?.m && !result?.data.r)
      {
        toast.warn(result?.data?.m);
        setIsLoading(false);
        handleClose();
      } else if (
        result?.data?.m === "Success" &&
        Number(status) === 1 &&
        !agencyAvailable
      )
      {
        removeLocalData();
        setIsLoading(false);
        handleClose();
        navigate(Path.AGENCY_NOT_FOUND);
      }
      setIsLoading(false);
    } catch (error)
    {
      setIsLoading(false);
      toast.error(error.message || "An error occurred");
    }
  };

  //Login dialogue State

  function userLoginHandler(e) {
    e.preventDefault();
    handleClose();
    navigate(Path.SIGN_IN);
  }

  const handleClose = () => {
    setloginFormDetails({ ...loginFormDetails, email: "", password: "" });
    setValidationError([]);
    setOpenLoginModal(false);
  };

  async function generateDeviceId(username, deviceInfo) {
    const combinedData = `${username}-${deviceInfo}`;

    const dataBuffer = new TextEncoder().encode(combinedData);

    try
    {
      const hashBuffer = await window.crypto.subtle.digest(
        "SHA-256",
        dataBuffer
      );

      // Convert the hash buffer to a Base64 string
      const base64String = btoa(
        String.fromCharCode(...new Uint8Array(hashBuffer))
      );

      return base64String;
    } catch (error)
    {
      console.error("Error generating device ID:", error);
      throw error; // Rethrow the error
    }
  }

  const handleGoogleLogin = async () => {
    try
    {
      setIsLoading(true);
      const resp = await signInWithPopup(auth, provider);
      if ( resp && resp?.user)
      {
        const newData = {
          login_type: 1,
          email: resp?.user?.email,
        };
        const res = await apiHelper.RegisterCustomer(newData);
        const status = res?.data?.r?.status;
        const role = res?.data?.r?.role;
        const agencyAvailable = res?.data?.r?.agency?.length > 0;
        if (res?.data?.s === 1 && status === 1)
        {
          localStorage.setItem("userInfo", JSON.stringify(res?.data?.r));
          localStorage.setItem("token", res?.data?.r?.token);
          const deviceId = await generateDeviceId(
            res?.data?.r?.id,
            res?.data?.r?.created_at
          );
          localStorage.setItem("deviceID", deviceId);
          await getFirebaseToken("add");
          setuserInfo(res?.data?.r);
          setuserProfileInfo(res?.data?.r);
          SetIsLoginGuest(false);

          toast.success(
            language === "en" ? "Login Successfully" : "تم تسجيل الدخول بنجاح"
          );

          location.pathname.includes("/property/details") && navigate(-1);
          if (
            location.pathname === Path.AGENCY_NOT_FOUND ||
            location.pathname === Path.APPROVAL
          )
          {
            navigate(Path.PROPERTY);
          } else if (location.pathname === Path.SIGN_IN)
          {
            navigate(Path.HOME);
          }
          setIsLoading(false);
          handleClose();
        } else if (Number(status) === 2 && (role === 1 || role === 2))
        {
          removeLocalData();
          setIsLoading(false);
          handleClose();
          navigate(Path.APPROVAL);
        } else if (Number(status) === -1 && (role === 1 || role === 2))
        {
          setIsLoading(false);
          handleClose();
          navigate(Path.REJECT);
        } else if (Number(status) === 0)
        {
          toast.error(
            language === "ar"
              ? "لم تتم المصادقة عليك."
              : "You are not authenticated."
          );
          removeLocalData();
          setIsLoading(false);
          handleClose();
        } else if (
          res?.data?.m === "Success" &&
          Number(status) === 1 &&
          !agencyAvailable
        )
        {
          removeLocalData();
          setIsLoading(false);
          navigate(Path.AGENCY_NOT_FOUND);
          handleClose();
        } else
        {
          if (res?.data?.m === "This email is already exists.")
          {
            toast.warn(
              language === "en"
                ? "This email is already exists."
                : "هذا البريد الإلكتروني موجود بالفعل."
            );
          } else
          {
            const userInfo = {
              email: resp?.user?.email,
              profile: resp?.user?.photoURL,
              f_name: resp?.user?.displayName.split(" ")[0],
              l_name: resp?.user?.displayName.split(" ")[1],
              uid: resp?.user?.uid,
            };
            setGoogleLoginData(userInfo);
            handleClose();
            navigate(`${Path.SIGN_IN}?google=1`);
          }
        }
      } else
      {
        setIsLoading(false);
      }
    } catch (error)
    {
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <Fragment>
      <Box
        className="menuList51 nav-link"
        sx={{ textTransform: "capitalize", cursor: "pointer" }}
        onClick={handleOpen}
      >
        {displayName}
      </Box>

      <Dialog
        className={"HideScrollBar"}
        open={openLoginModal}
        onClose={handleClose}
        onBackdropClick={(e) => e.stopPropagation()}
        onClick={(e) => e.preventDefault()}
        PaperProps={{
          sx: {
            borderRadius: 5, // Adjusted border radius
            width: {
              xs: "90%",
              sm: "55%",
              md: "50%",
              lg: "33%",
              xl: "27%",
            },
            position: "relative",
          },
        }}
        sx={{
          backdropFilter: "blur(4px)",
        }}
      >
        <Box
          sx={{
            bgcolor: theme.palette.secondary.main,
            padding: "0 10px",
            color: theme.palette.primary.main,
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
              position: "absolute",
              right: "0.2rem",
            }}
          >
            <Box sx={{ m: "0.7rem", cursor: "pointer " }} onClick={handleClose}>
              <CloseRoundedIcon />
            </Box>
          </Box>
          {
            <Box>
              <DialogTitle>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "colomn",
                  }}
                >
                  {themeMode === "light" ? (
                    <img
                      src="/assets/image/home/logo.svg"
                      alt="logo"
                      width={"100px"}
                      height={"100%"}
                    />
                  ) : (
                    <img
                      src="/assets/image/home/dlogo.svg"
                      alt="logo"
                      width={"100px"}
                      height={"100%"}
                    />
                  )}
                </Box>
              </DialogTitle>
              <Typography
                style={{
                  margin: "0px",
                  fontWeight: "600",
                  textAlign: "center",
                  fontSize: "1.3rem",
                }}
                variant="h4"
              >
                {/* Lets log You in */}
                {t("signin.login.title")}
              </Typography>
              <Typography
                sx={{ fontSize: "16px", textAlign: "center", mt: "0.1em" }}
              >
                {/* Welcome back, you’ve been missed! */}
                {t("signin.login.content")}
              </Typography>
              <DialogContent>
                <Box
                  sx={{
                    fontSize: "9px",
                    mb: "1rem",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <InputComponent
                    type="email"
                    id="email"
                    name="email"
                    onChange={handleFormDetails}
                    isError={validationError?.find((x) => {
                      return x.key === "email" ? true : false;
                    })}
                    helperText={
                      validationError.find((x) => x.key === "email")?.message
                    }
                    sx={{
                      width: "100%",
                      borderRadius: "12px",
                      input: { color: `#1f2438 !important` },
                      bgcolor: theme.palette.input.main,
                      " .MuiOutlinedInput-input": {
                        textAlign: language === "ar" ? "right" : "left",
                      },
                    }}
                    value={loginFormDetails.email}
                    // className="form-control"
                    placeholder={t("signin.login.email")}
                  />
                </Box>

                <Box sx={{ justifyContent: "center" }}>
                  <InputComponentOutline
                    sx={{
                      bgcolor: theme.palette.input.main,
                      width: "100%",
                      borderRadius: "12px",
                      " .MuiOutlinedInput-input": {
                        textAlign: language === "ar" ? "right" : "left",
                      },
                    }}
                    id="password"
                    name="password"
                    onChange={handleFormDetails}
                    isError={validationError?.find((x) => {
                      return x.key === "password" ? true : false;
                    })}
                    helperText={
                      validationError.find((x) => x.key === "password")?.message
                    }
                    onKeyPress={(e) => {
                      if (e.charCode === 13)
                      {
                        e.preventDefault();
                        handleFormSubmission();
                      }
                    }}
                    type={showPassword ? "text" : "password"}
                    value={loginFormDetails.password}
                    placeholder={t("signin.login.pass")}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          sx={{ color: "#1f2438" }}
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </Box>

                <Box
                  sx={{ width: "100%", display: "flex", justifyContent: "end" }}
                >
                  <Box
                    sx={{
                      fontSize: "15px",
                      mr: "0.6rem",
                      cursor: "pointer",
                      mt: "10px",
                    }}
                  >
                    {/* Forgot Password ? */}
                    <ForgotPasswordDialogue />
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: "0.5rem",
                    // p: "1rem",
                    width: "100%",
                  }}
                >
                  <Button
                    variant="contained"
                    size="large"
                    fullWidth
                    sx={{
                      padding: "0.3em 5em",
                      textTransform: "capitalize",
                      bgcolor: theme.palette.gold.main,
                      borderRadius: "12px",
                      fontSize: "1.1rem",
                      color: "white",
                      "&:hover": {
                        backgroundColor: theme.palette.gold.main,
                      },
                    }}
                    onClick={handleFormSubmission}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <CircularProgress
                          disableShrink
                          sx={{ color: "#1f2438", margin: "0.2rem" }}
                          size={20}
                        />
                      </>
                    ) : (
                      `${t("signin.login.button")}`
                    )}
                  </Button>
                </Box>

                <Divider sx={{ m: "1rem 0.5rem", fontSize: "0.7rem" }}>
                  {" "}
                  {t("signin.login.or")}
                </Divider>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "1rem",
                  }}
                >
                  <Box
                    sx={{
                      // width: "1.3rem",
                      bgcolor: "#fff",
                      padding: "12px",
                      borderRadius: "12px",
                      cursor: "pointer",
                    }}
                    onClick={handleGoogleLogin}
                  >
                    {" "}
                    <img
                      style={{ width: "100%" }}
                      src="/assets/image/sign/google.svg"
                      alt=""
                      srcSet=""
                    />{" "}
                  </Box>
                  <Box
                    sx={{
                      width: "1.3rem",
                      bgcolor: "#fff",
                      padding: "0 12px",
                      borderRadius: "12px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <AppleLogo />
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: "1rem",
                    fontSize: "0.9rem",
                  }}
                >
                  <span>
                    {" "}
                    {t("signin.login.account")}
                    <Link
                      onClick={userLoginHandler}
                      style={{
                        fontWeight: "600",
                        color: theme.palette.dark.main,
                      }}
                    >
                      <span style={{ textDecoration: "underline" }}>
                        {" "}
                        {t("signin.login.regi")}
                      </span>
                    </Link>
                  </span>
                </Box>
              </DialogContent>
            </Box>
          }
        </Box>
      </Dialog>
    </Fragment>
  );
}
