import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import HelpIcon from "@mui/icons-material/Help";
import LoginIcon from "@mui/icons-material/Login";
import { Link, useNavigate } from "react-router-dom";
import Path from "../../common/Path";
import CustomizedSwitches from "./ModeToggleSwitch/ModeToggleSwitch";
import { Divider, useTheme } from "@mui/material";
import { baseUrl } from "../../common/Constant";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../Context/LanguageContext";
import AddHomeIcon from "@mui/icons-material/AddHome";
import ChatIcon from "@mui/icons-material/Chat";
import NotificationModal from "../../screen/Notification/NotificationModal";
import apiHelper from "../../common/API/ApiHelper";
import ApartmentIcon from "@mui/icons-material/Apartment";
import defaultImg from "../../common/assets/image/User.svg";

export default function NavbarDrawer(props) {
  const { i18n, t } = useTranslation();
  const { setLanguage } = React.useContext(LanguageContext);
  const [isImageError, setIsImageError] = React.useState(false);
  const theme = useTheme();
  let {
    languages,
    isLogInGuest,
    drawerState,
    setDrawerState,
    themeMode,
    setThemeMode,
    handleOpen,
    userInfo,
    setUserProfileFunctions,
    setUserLogoutAction,
    logoutLogoutAction,
    handleLogOut,
    handleDeleteUserAccount,
    setuserInfo,
    setuserProfileInfo,
    // isScroled,
  } = props;
  const navigate = useNavigate();

  const handleLoginClick = () => {
    handleOpen();
  };

  const renderUserProfileImage = () => {
    if (isImageError) {
      return (
        <Box
          width="100%"
          height="100%"
          borderRadius="50%"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "2rem",
            fontWeight: "600",
            opacity: "0.7",
          }}
        >
          {userInfo?.email?.charAt(0)}
        </Box>
      );
    } else {
      const imageUrl =
        userInfo && userInfo?.role === 2
          ? userInfo?.agency_logo
            ? `${baseUrl}/uploads/agency/logo/${userInfo?.agency_logo}`
            : defaultImg
          : userInfo?.profile
          ? `${baseUrl}/uploads/user/profile/${userInfo?.profile}`
          : defaultImg;
      return (
        <img
          style={{ borderRadius: "50%", objectFit: "cover" }}
          height="100%"
          width="100%"
          src={imageUrl}
          alt={userInfo?.profile ? "User Profile Image" : "Default User Image"}
          onError={() => {
            setIsImageError(true);
          }}
        />
      );
    }
  };

  const handleLanguageChange = async (lang) => {
    try {
      const cd = lang === "ar" ? 2 : 1; //eslint-disable-next-line
      const res = await apiHelper.updateLanguageChange(cd);
    } catch (error) {}
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "left" ? "auto" : 350,
        bgcolor: theme.palette.secondary.main,
        // height: "100%",
        padding: "25px",
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box>
        <h2 style={{ textAlign: "center" }}>
          {themeMode === "light" ? (
            <img
              src="/assets/image/home/logo.svg"
              height={80}
              alt=""
              srcSet=""
            />
          ) : (
            <img
              src="/assets/image/home/dlogo.svg"
              height={80}
              alt=""
              srcSet=""
            />
          )}
        </h2>

        <div>
          {/* <Divider /> */}

          <List>
            {[
              `${t("navbar.menu.home")}`,
              `${t("navbar.menu.propertyS")}`,
              `${t("navbar.menu.property")}`,
              `${t("navbar.menu.chat")}`,
              `${t("navbar.menu.about")}`,
              `${t("navbar.menu.contact")}`,
              `${t("navbar.menu.help")}`,
            ].map((text, index) => (
              <Link
                style={{ color: theme.palette.primary.main }}
                key={index}
                to={
                  text === `${t("navbar.menu.home")}`
                    ? Path.HOME
                    : text === `${t("navbar.menu.property")}`
                    ? Path.ADD_PROPERTY
                    : text === `${t("navbar.menu.chat")}`
                    ? Path.CHAT
                    : text === `${t("navbar.menu.about")}`
                    ? Path.ABOUT
                    : text === `${t("navbar.menu.contact")}`
                    ? Path.CONTACT
                    : text === `${t("navbar.menu.help")}`
                    ? Path.HELP
                    : text === `${t("navbar.menu.propertyS")}` && Path.PROPERTY
                }
              >
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon
                      sx={{
                        minWidth: "40px",
                        color: theme.palette.primary.main,
                      }}
                      variant={"body2"}
                    >
                      {text === `${t("navbar.menu.home")}` ? (
                        <HomeIcon />
                      ) : text === `${t("navbar.menu.about")}` ? (
                        <InfoIcon />
                      ) : text === `${t("navbar.menu.contact")}` ? (
                        <ContactPageIcon />
                      ) : text === `${t("navbar.menu.help")}` ? (
                        <HelpIcon />
                      ) : text === `${t("navbar.menu.property")}` ? (
                        <AddHomeIcon />
                      ) : text === `${t("navbar.menu.chat")}` ? (
                        <ChatIcon />
                      ) : text === `${t("navbar.menu.propertyS")}` ? (
                        <ApartmentIcon />
                      ) : null}
                    </ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItemButton>
                </ListItem>
              </Link>
            ))}

            <Box
              sx={{
                padding: "8px 16px",
                display: "flex",
                justifyContent: "start",
                gap: "1rem",
                alignItems: "center",
              }}
            >
              <NotificationModal />
            </Box>

            <Box
              sx={{
                padding: "8px 16px",
                display: isLogInGuest ? "flex" : "none",
                justifyContent: "start",
                gap: "1rem",
                alignItems: "center",
                // ml: "0.1rem",
              }}
              onClick={handleLoginClick}
            >
              <LoginIcon /> {t("navbar.menu.login")}
            </Box>
            <Box
              sx={{
                padding: "8px 16px",
                display: isLogInGuest ? "none" : "flex",
                justifyContent: "start",
                gap: "1rem",
                alignItems: "center",
                // ml: "0.1rem",
              }}
              onClick={() => {
                navigate(Path.USER_PROFILE);
                setUserProfileFunctions({
                  setUserLogoutAction,
                  logoutLogoutAction,
                  handleLogOut,
                  handleDeleteUserAccount,
                  setuserInfo,
                  setuserProfileInfo,
                });
              }}
            >
              <div
                style={{
                  backgroundColor: "#dbdbdb",
                  borderRadius: "50%",
                  width: "1.4rem",
                  height: "1.4rem",
                  display: "flex",
                  justifyContent: "center",
                  border: "1px solid #1f2438",
                  // padding: "1px",
                }}
              >
                {userInfo && renderUserProfileImage()}
              </div>
              <span>{t("navbar.menu.profile")}</span>
            </Box>
          </List>
        </div>
      </Box>
      <Divider />

      <div
        style={{
          display: "flex",
          // border: "1px solid red",
        }}
      >
        {languages.map((lang) => {
          return (
            <div
              onClick={() => {
                i18n.changeLanguage(lang.code);
                handleLanguageChange(lang.code);
                setLanguage(lang.code);
              }}
              className="language-item"
              style={{
                padding: "0.1rem 1rem",
                // border: "1px solid red",
                cursor: "pointer",
                margin: "0.5rem",
              }}
              key={lang.code}
            >
              {lang.title}
            </div>
          );
        })}
      </div>
      <Divider />

      <Box
        sx={{
          padding: "1rem",
          display: "flex",
          justifyContent: "start",
          // gap: "1rem",
          ml: "0.3rem",
          // border: "1px solid red",
        }}
      >
        <CustomizedSwitches themeMode={themeMode} setThemeMode={setThemeMode} />
      </Box>
    </Box>
  );

  return (
    <div>
      <React.Fragment key={"left"}>
        <Button onClick={toggleDrawer("left", true)}>{"left"}</Button>
        <Drawer
          anchor={"left"}
          open={drawerState["left"]}
          onClose={toggleDrawer("left", false)}
        >
          {list("left")}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
