import { Footer_EN_json } from "../../component/Footer/FooterTrans";
import { Navabar_EN_json } from "../../component/Navbar/NavbarTrans";
import { About_EN_json } from "../../screen/About/AboutTrans";
import { AddProperty_EN_json } from "../../screen/AddProperty/AddPropertyTrans";
import { Chat_EN_json } from "../../screen/Chat/ChatTrans";
import { Contact_EN_json } from "../../screen/Contact/ContactTrans";
import { Gmap_EN_Trans } from "../../screen/GoogleMap/GMapTrans";
import { Help_EN_json } from "../../screen/Help/HelpTrans";
import { Home_EN_json } from "../../screen/Home/HomeTrans";
import { Notification_EN_json } from "../../screen/Notification/NotificationTrans";
import { Property_EN_json } from "../../screen/Property/PropertyTrans";
import { Evaluation_EN_json } from "../../screen/PropertyEvaluation/EvaluationTran";
import { Sign_EN_json } from "../../screen/SignIn/SignTrans";
import { Registration_EN_json } from "../../screen/SignIn/user/RegiTrans";
import { Validation_EN_json } from "../ValidationTrans";

export const English = {
  translation: {
    landingPage: Home_EN_json,
    about: About_EN_json,
    contact: Contact_EN_json,
    help: Help_EN_json,
    footer: Footer_EN_json,
    navbar: Navabar_EN_json,
    property: Property_EN_json,
    signin: Sign_EN_json,
    addProperty: AddProperty_EN_json,
    validation: Validation_EN_json,
    eval:Evaluation_EN_json,
    regi:Registration_EN_json,
    chat:Chat_EN_json,
    notf:Notification_EN_json,
    map:Gmap_EN_Trans,
  }
};
