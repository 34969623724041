import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer,Zoom } from "react-toastify";
import "./common/i18next/I18next.js"
import LanguageContextProvider from "./Context/LanguageContext.jsx";
import { BrowserRouter } from "react-router-dom";
import Loader from "./common/Loader.jsx";
import 'animate.css/animate.min.css';



const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Suspense fallback={() => <Loader />}>
    <BrowserRouter>
      <LanguageContextProvider>
        <App />
      </LanguageContextProvider>
    </BrowserRouter>
    <ToastContainer closeOnClick draggable icon newestOnTop transition={Zoom}/>
  </Suspense>
);

reportWebVitals();
