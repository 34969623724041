import { useEffect } from "react";
import apiHelper from "./API/ApiHelper";
import { useNavigate } from "react-router-dom";
import Path from "./Path";

export default function Middleware({ children }) {
  const nav = useNavigate();
  let userInfo;
  try
  {
    userInfo = JSON.parse(localStorage.getItem("userInfo"));
  } catch (error)
  {
    userInfo = null;
  }

  const removeLocalData = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("deviceID");
    localStorage.removeItem("userInfo");
  };

  useEffect(() => {
    const handleVerify = async () => {
      if (userInfo && userInfo?.id)
      {
        try
        {
          const res = await apiHelper.fetchUserDetailsById(userInfo?.id);
          if (res?.data?.r?.status === 2)
          {
            nav(Path.APPROVAL);
          } else if (res?.data?.r?.status === -1)
          {
            nav(Path.REJECT);
          } else if (
            res?.data?.r?.role === 1 &&
            res?.data?.r?.agency?.length === 0 &&
            res?.data?.r?.status === 1
          )
          {
            nav(Path.AGENCY_NOT_FOUND);
            removeLocalData();
          } else
          {
            return children;
          }
        } catch (error)
        {
          console.error("Error fetching user details:", error);
        }
      }
    };

    handleVerify(); //eslint-disable-next-line
  }, [nav]);

  return children;
}
