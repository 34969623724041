import { createTheme } from "@mui/material";
import { TextTheme } from "./TextTheme";

export const theme = (mode) => {
  return createTheme({
    typography: {
      fontFamily: [`'Roboto'`],
      mainHeading: generateTheFont("mainHeading"),
      subHeading: generateTheFont("subHeading"),
      button: generateTheFont("button"),
      menu: generateTheFont("menu"),
      text: generateTheFont("text"),
      proHead: generateTheFont("proHead"),
    },
    palette: {
      mode,
      ...(mode === "light"
        ? {
            primary: {
              main: "#1f2438",
            },
            homeCard: {
              main: "#f3f3f3",
            },
            secondary: {
              main: "#EEEEEE",
            },
            activeTab: {
              main: "#3e4358",
            },
            paragraph: {
              main: "rgba(31, 36, 56, 0.75)",
            },
            bg: {
              main: "#DBDBDB",
            },

            tbg: {
              main: "rgba(238, 238, 238, 0.55)",
            },
            default: {
              main: "#ffffff",
            },
            light: {
              main: "#EEE",
            },
            dark: {
              main: "#1f2438",
              light: "#1f243895",
            },
            gold: {
              main: "#1f2438",
            },
            cardbg: {
              main: "#DBDBDB",
            },
            input: {
              main: "#DBDBDB",
            },
            navbarBG: {
              main: "#EEE",
            },
            divider: "rgba(31, 36, 56, 0.10);",
            newBg_footer: "#DBDBDB",
            darkField: "#ffffff4d",
            newsBox: {
              main: "#1f2438",
            },
          }
        : {
            primary: {
              main: "#ffffff",
            },
            homeCard: {
              main: "#0c1230",
            },
            secondary: {
              main: "#1f2438",
            },
            paragraph: {
              main: "rgba(255, 255, 255, 0.75)",
            },
            bg: {
              main: "#23283E",
            },
            tbg: {
              main: "rgba(31, 36, 56, 0.50)",
            },
            default: {
              main: "#ffffff",
            },
            activeTab: {
              main: "#EEEEEE70",
            },
            light: {
              main: "#1f2438",
            },
            dark: {
              main: "#EEE",
              light: "#EEEEEE70",
            },
            gold: {
              main: "#D4AB77",
            },

            divider: "rgba(238, 238, 238, 0.50);",

            info: {
              main: "#fff",
            },
            cardbg: {
              main: "#FFF",
            },
            input: {
              main: "#fff",
            },
            navbarBG: {
              main: "#1f2438",
            },
            newBg_footer: "#181C2F",
            darkField: "#1f243870",
            newsBox: {
              main: "#23283E",
            },
          }),
    },
  });
};
const generateTheFont = (font) => {
  const sizes = ["xl", "lg", "md", "sm", "xs"];
  return {
    fontSize: TextTheme[font].default,
    [createTheme().breakpoints.up(sizes[4])]: {
      fontSize: TextTheme[font][sizes[4]],
    }, // eslint-disable-next-line
    fontSize: TextTheme[font].default,
    [createTheme().breakpoints.up(sizes[3])]: {
      fontSize: TextTheme[font][sizes[3]],
    }, // eslint-disable-next-line
    fontSize: TextTheme[font].default,
    [createTheme().breakpoints.up(sizes[2])]: {
      fontSize: TextTheme[font][sizes[2]],
    }, // eslint-disable-next-line
    fontSize: TextTheme[font].default,
    [createTheme().breakpoints.up(sizes[1])]: {
      fontSize: TextTheme[font][sizes[1]],
    }, // eslint-disable-next-line
    fontSize: TextTheme[font].default,
    [createTheme().breakpoints.up(sizes[0])]: {
      fontSize: TextTheme[font][sizes[0]],
    },
  };
};
