import { useTheme } from "@mui/material/styles";
import { Box, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Path from "../../common/Path";
import apiHelper from "../../common/API/ApiHelper";
import { baseUrl } from "../../common/Constant";
import UserProfileUpdateDialogue from "../../component/Navbar/user/UserProfileUpdateDropDown";
import UserLogoutDialogue from "../../component/Navbar/user/UserLogoutDialogue";
import UserDeleteDialogue from "../../component/Navbar/user/UserDeleteDialogue";
import Loader from "../../common/Loader";
import { useTranslation } from "react-i18next";
import defaultImg from "../../common/assets/image/User.svg";

export default function ProfileScreen({ themeMode, userProfileFunctions }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const [userInfo, setuserInfo] = useState();
  const [loading, setloading] = useState(false);
  const { t } = useTranslation();
  const [isImageError, setIsImageError] = useState(false);

  const renderUserProfileImage = () => {
    const firstLetter = userInfo?.f_name
      ? userInfo?.f_name?.at(0)?.toUpperCase()
      : userInfo?.company_name?.at(0)?.toUpperCase();

    if (isImageError) {
      return (
        <Box
          width="100%"
          height="100%"
          borderRadius="50%"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "2rem",
            fontWeight: "600",
            opacity: "0.7",
          }}
        >
          {firstLetter}
        </Box>
      );
    } else {
      const imageUrl =
        userInfo && userInfo.role === 2
          ? userInfo?.agency_logo
            ? `${baseUrl}/uploads/agency/logo/${userInfo?.agency_logo}`
            : defaultImg
          : userInfo?.profile
          ? `${baseUrl}/${userInfo?.profile}`
          : defaultImg;
      return (
        <img
          style={{ borderRadius: "12px", objectFit: "cover" }}
          height="100%"
          width="100%"
          src={imageUrl}
          alt={userInfo?.profile ? "User Profile Image" : "Default User Image"}
          onError={() => {
            setIsImageError(true);
          }}
        />
      );
    }
  };

  const fetchUserInfo = async () => {
    try {
      setloading(true);
      const res = await apiHelper.loginUserDetails();
      res && res?.data?.s && setuserInfo(res?.data?.r);
      setloading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    window.scrollTo({ behavior: "instant", top: "0" });
  }, []);

  useEffect(() => {
    fetchUserInfo();
  }, []);

  return loading ? (
    <Box
      sx={{
        minHeight: `calc( 100vh - 143px)`,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Loader />
    </Box>
  ) : (
    userInfo && (
      <Box
        sx={{
          minHeight: `calc(100vh)`,
          position: "relative",
          bgcolor: "#dbdbdb",
        }}
      >
        <Box
          sx={{
            display: "flex",
            height: "60px",
            // bgcolor: `${theme.palette.secondary.main}`,
            color: theme.palette.dark.main,
            alignItems: "center",
            width: "100%",
            position: "sticky",
            zIndex: 1,
            top: "0px",
          }}
        >
          <Box
            sx={{ width: "50px", display: "flex", justifyContent: "end" }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBackIosIcon sx={{ color: theme.palette.dark.main }} />
          </Box>
          <Box sx={{ width: `calc(100% - 50px)` }}>
            <Typography
              variant="text"
              sx={{
                fontWeight: "600",
                display: "flex",
                color: theme.palette.dark.main,
                justifyContent: "start",
                pl: "33%",
              }}
            >
              User Profile
            </Typography>
          </Box>
        </Box>
        <Box sx={{ height: `calc(100vh - 60px )` }}>
          <Box
            sx={{
              bgcolor: "#dbdbdb",
              padding: "1rem",
              height: "60px",
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              overflow: "hidden",
              // bgcolor: theme.palette.secondary.main,
              // color: theme.palette.dark.main,
            }}
          >
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Box sx={{ mr: "10px" }}>
                <div
                  style={{
                    borderRadius: "12px",
                    height: "60px",
                    width: "60px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "1px solid #0000003b",
                    position: "relative",
                    // bgcolor: theme.palette.secondary.main,
                    // color: theme.palette.dark.main,
                    bgcolor: "#dbdbdb",
                  }}
                >
                  {userInfo && renderUserProfileImage()}
                </div>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "1.3rem",
                }}
              >
                {userInfo?.f_name
                  ? `${userInfo?.f_name} ${userInfo?.l_name}`
                  : userInfo?.company_name}
              </Box>
            </Box>
          </Box>
          <Box id="list">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                padding: "1rem ",
                bgcolor: "#dbdbdb",
                // bgcolor: theme.palette.secondary.main,
                // color: theme.palette.dark.main,
              }}
            >
              <Box sx={{ mr: "15px" }}>
                <img
                  src="/assets/image/property/profile.svg"
                  height={30}
                  alt=""
                  srcSet=""
                />{" "}
              </Box>
              <Typography sx={{ fontSize: "1.2rem" }}>
                <UserProfileUpdateDialogue
                  themeMode={themeMode}
                  userInfo={userInfo}
                  fetchUserInfo={fetchUserInfo}
                  color={theme.palette.dark.main}
                  // userProfileFunctions={userProfileFunctions}
                />
              </Typography>
            </Box>
            <Divider sx={{ bgcolor: theme.palette.divider }} />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                padding: "1rem ",
                bgcolor: "#dbdbdb",
              }}
              onClick={() => {
                navigate(`${Path.PROPERTY_HISTORY}?user=1`);
              }}
            >
              <Box sx={{ mr: "15px" }}>
                <img
                  src="/assets/image/property/history.svg"
                  height={30}
                  alt=""
                  srcSet=""
                />{" "}
              </Box>
              <Typography sx={{ fontSize: "1.2rem" }}>
                {/* Property History */}
                {t("navbar.menu.userProfile.history")}
              </Typography>
            </Box>
            <Divider sx={{ bgcolor: theme.palette.divider }} />

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                padding: "1rem ",
                bgcolor: "#dbdbdb",
              }}
              onClick={() => {
                navigate(`${Path.REQUESTED_PROPERTY}?user=1`);
              }}
            >
              <Box sx={{ mr: "15px" }}>
               <FavoriteBorderIcon />
               </Box>
              <Typography sx={{ fontSize: "1.2rem" }}>
                {t("navbar.menu.userProfile.wishlist")}
              </Typography>
            </Box>
            <Divider sx={{ bgcolor: theme.palette.divider }} />

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                padding: "1rem ",
                bgcolor: "#dbdbdb",
              }}
              onClick={() => {
                navigate(`${Path.REQUESTE_TO_USERS_PROPERTY}?user=1`);
              }}
            >
              <Box sx={{ mr: "15px" }}>
                <img
                  src="/assets/image/property/his.png"
                  height={30}
                  alt=""
                  srcSet=""
                />{" "}
              </Box>
              <Typography sx={{ fontSize: "1.2rem" }}>
                {t("navbar.menu.userProfile.req2yProp")}
              </Typography>
            </Box>
            <Divider sx={{ bgcolor: theme.palette.divider }} />

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                padding: "1rem ",
                bgcolor: "#dbdbdb",
              }}
            >
              <Box sx={{ mr: "15px" }}>
                <img
                  src="/assets/image/property/login.svg"
                  height={30}
                  alt=""
                  srcSet=""
                />{" "}
              </Box>
              <Typography sx={{ fontSize: "1.2rem" }}>
                <UserLogoutDialogue
                  themeMode={themeMode}
                  handleLogOut={
                    userProfileFunctions && userProfileFunctions?.handleLogOut
                  }
                />
              </Typography>
            </Box>
            <Divider sx={{ bgcolor: theme.palette.divider }} />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                padding: "1rem ",
                bgcolor: "#dbdbdb",
              }}
            >
              <Box sx={{ mr: "15px" }}>
                <img
                  src="/assets/image/property/delete.svg"
                  height={30}
                  alt=""
                  srcSet=""
                />{" "}
              </Box>
              <Typography sx={{ fontSize: "1.2rem" }}>
                <UserDeleteDialogue
                  themeMode={themeMode}
                  handleDeleteUserAccount={
                    userProfileFunctions &&
                    userProfileFunctions?.handleDeleteUserAccount
                  }
                />
              </Typography>
            </Box>
            <Divider sx={{ bgcolor: theme.palette.divider }} />
          </Box>
        </Box>
      </Box>
    )
  );
}
